import { getResourceGallery, getResourceGallerys } from "../api/resourcegallery";
import { useMemo } from "react";

export const useResourceGallerys = () => {
  return useMemo(() => {
    const handleFetchResourceGallerys = async ({
      featured,
      tag,
      setLoading = () => {},
    }) => {
      setLoading(true);

      try {
        const { data } = await getResourceGallerys(tag, featured);

        /* gestionar quan la resposta es buida (si es dona el cas) */
        if (!data) return [];

        return data || [];
      } catch (error) {
        /* gestionar el error */
        console.log("could not fetch podcasts ", error);
        return { error };
      } finally {
        setLoading(false);
      }
    };

    const handleFetchResourceGallery = async ({ id, setLoading = () => {} }) => {
      setLoading(true);

      try {
        const { data } = await getResourceGallery(id);
        /* gestionar quan la resposta es buida (si es dona el cas) */
        if (!data) return {};
        return { data };
      } catch (error) {
        /* TODO -> Handle error */
        /* gestionar el error */
        console.log("could not fetch podcast with id: ", id, error);
        return { error };
      } finally {
        setLoading(false);
      }
    };

    return {
      handleFetchResourceGallerys,
      handleFetchResourceGallery,
    };
  }, []);
};
