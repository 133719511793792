import React, { useState, useEffect } from "react";
import { injectIntl } from "react-intl";
import test1 from "../assets/test1.jpg";
import test3 from "../assets/test3.jpg";
import CustomHelmet from "../components/seo/CustomHelmet";
import { useInView } from "react-intersection-observer";
import clsx from "clsx";
import { useLang } from "../translations/i18n";
import { RUTAS } from "../constants/routes.constants";

function ImasD({ intl }) {
  const [dataThehubs, setDataThehubs] = useState([]);

  const lang = useLang();
  const { ref, inView /*, entry*/ } = useInView({
    /* Optional options */
    threshold: 0,
    delay: 1500,
    triggerOnce: true,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    // 	const asyncFetchThehubs = async () => {
    // 		const dataThehubs = await handleFetchThehubs({ })
    // 		setDataThehubs(dataThehubs)
    // 	}
    // 	asyncFetchThehubs()
    // 	// eslint-disable-next-line
  }, []);

  return (
    <>
      <CustomHelmet
        title={intl.formatMessage({ id: "PERFUMES.TITLESEO" }) || ""}
        description={
          intl.formatMessage({ id: "PERFUMES.DESCRIPTIONSEO" }) || ""
        }
      />
      <div className="container px-0">
        <div className="row my-3">
          <div className="col-12 col-lg-8">
            <div className="bg-imasdback1 h-100"></div>
          </div>
          <div className="col-12 col-lg-4 ">
            <div className="bglightgrey2 h-100 d-flex align-items-center p-5">
              <div dangerouslySetInnerHTML={ { __html: intl.formatMessage({ id: 'IMASD.TEXT1' }) } } />
            </div>
          </div>
        </div>
        <div className="row my-3">
          <div className="col-12 col-lg-5">
            <div className="bglightgrey h-100 d-flex align-items-center p-5">
              <div dangerouslySetInnerHTML={ { __html: intl.formatMessage({ id: 'IMASD.TEXT2' }) } } />
            </div>
          </div>
          <div className="col-12 col-lg-7">
            <div className="bg-imasdback2 h-100"></div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 text-center py-5">
            <h2>
              <div
              className="cooperhewitt"
              dangerouslySetInnerHTML={ { __html: intl.formatMessage({ id: 'IMASD.TEXTNUESTRODEPARTAMENTO' }) } } />
            </h2>
          </div>
        </div>
        <div className="row my-3">
          <div className="col-12 col-lg-7">
            <div className="bg-imasdback3 h-100"></div>
          </div>
          <div className="col-12 col-lg-5">
            <div className="bglightgrey3 h-100 d-flex align-items-center p-5">
              <div>
                <div
                className="apercuregular list"
                dangerouslySetInnerHTML={ { __html: intl.formatMessage({ id: 'IMASD.TEXT3' }) } } />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default injectIntl(ImasD);
