import React, { useState, useEffect } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import test1 from "../assets/test1.jpg";
import test3 from "../assets/test3.jpg";
import CustomHelmet from "../components/seo/CustomHelmet";
import { useInView } from "react-intersection-observer";
import clsx from "clsx";
import { useLang } from "../translations/i18n";
import { RUTAS } from "../constants/routes.constants";
import TranslatedLink from "../components/navigation/TranslatedLink";

function Cosmetics({ intl }) {
  const lang = useLang();
  const { ref, inView /*, entry*/ } = useInView({
    /* Optional options */
    threshold: 0,
    delay: 1500,
    triggerOnce: true,
  });

  // useEffect(() => {
  // 	window.scrollTo(0, 0)
  // 	const asyncFetchThehubs = async () => {
  // 		const dataThehubs = await handleFetchThehubs({ })
  // 		setDataThehubs(dataThehubs)
  // 	}
  // 	asyncFetchThehubs()
  // 	// eslint-disable-next-line
  // }, [dataThehubs?.fullName])

  return (
    <>
      <CustomHelmet
        title={intl.formatMessage({ id: "COSMETICS.TITLESEO" }) || ""}
        description={
          intl.formatMessage({ id: "COSMETICS.DESCRIPTIONSEO" }) || ""
        }
      />
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 bg-cosmeticsheader p-5">
            <h1 className="text-white cooperhewitt">
              <div
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage({ id: "COSMETICS.COSMETICS" }),
                }}
              />
            </h1>
          </div>
        </div>
      </div>
      <div className="container px-0">
        <div className="row">
          <div className="col-12 col-lg-6 my-4">
            <div className="h-100 sup-bg-cosmeticsskincare">
              <TranslatedLink to={RUTAS.SKINCARE} className="w-100">
                <div className="bg-cosmeticsskincare h-100 text-center py-4">
                  <h1 className="text-white cooperhewitt">
                    <FormattedMessage id="MENU.SKINCARE" />
                  </h1>
                </div>
              </TranslatedLink>
            </div>
          </div>
          <div className="col-12 col-lg-6 my-4">
            <div className="h-100 sup-bg-cosmeticsmakeup">
              <TranslatedLink to={RUTAS.MAKEUP} className="w-100">
                <div className="bg-cosmeticsmakeup h-100 text-center py-4">
                  <h1 className="text-white cooperhewitt">
                    <FormattedMessage id="MENU.MAKEUP" />
                  </h1>
                </div>
              </TranslatedLink>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default injectIntl(Cosmetics);
