import { getMedias } from "../api/media";
import { useMemo } from "react";

export const useMedia = () => {
  return useMemo(() => {
    const handleFetchMedia = async ({
      featured,
      tag,
      setLoading = () => {},
    }) => {
      setLoading(true);

      try {
        const { data } = await getMedias(tag, featured);

        /* gestionar quan la resposta es buida (si es dona el cas) */
        if (!data) return [];

        return data || [];
      } catch (error) {
        /* gestionar el error */
        console.log("could not fetch podcasts ", error);
        return { error };
      } finally {
        setLoading(false);
      }
    };
    
    return {
      handleFetchMedia
    };
  }, []);
};
