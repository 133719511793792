import React, { useState, useEffect } from 'react'
import { injectIntl } from 'react-intl'
import test1 from '../assets/test1.jpg'
import test3 from '../assets/test3.jpg'
import CustomHelmet from '../components/seo/CustomHelmet'
import { useInView } from 'react-intersection-observer';
import clsx from 'clsx'
import { useLang } from '../translations/i18n'
import { RUTAS } from '../constants/routes.constants'
import makeupheader from '../assets/makeup-header.jpg'
import makeup2 from '../assets/makeup2.jpg'
import makeup3 from '../assets/makeup3.jpg'

function MakeUp({ intl }) {

	const lang = useLang()
	const { ref, inView/*, entry*/ } = useInView({
    /* Optional options */
    threshold: 0,
		delay: 1500,
		triggerOnce: true
	});

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	return (<>
		<CustomHelmet
			title={intl.formatMessage({ id: 'MAKEUP.TITLESEO' }) || ''}
			description={intl.formatMessage({ id: 'MAKEUP.DESCRIPTIONSEO' }) || ''}
		/>
		<div className='container pt-5'>
			<div className='row'>
				<div className='col-12 col-lg-6 colorgrey'>
					<h1>Make Up</h1>
					<h2><div dangerouslySetInnerHTML={ { __html: intl.formatMessage({ id: 'MAKEUP.SUBTITLE' }) } } /></h2>
				</div>
				<div className='col-12 col-lg-6 colorgrey py-2' style={{'z-index': '1000'}}>
					<img src={makeupheader} className='img-fluid makeupheader' />
				</div>
			</div>
		</div>
		<div className='container-fluid'>
			<div className='row'>
				<div className='col-12 bg-makeup1 p-5'>
				</div>
			</div>
		</div>
		<div className='container pt-5'>
			<div className='row'>
				<div className='col-12 col-lg-6 colorgrey'>
					<img src={makeup3} className='img-fluid mt-5' />
				</div>
				<div className='col-12 col-lg-6 colorgrey py-2'>
					<img src={makeup2} className='img-fluid mb-5' />
				</div>
			</div>
		</div>
		<div className='container my-4'>
			<div className='row'>
				<div className='col-12 bg-makeup4 p-5'>
				</div>
			</div>
		</div>
	</>
  	)
}

export default injectIntl(MakeUp)
