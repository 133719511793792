import React, { useState, useEffect } from "react";
import { injectIntl } from "react-intl";
import test1 from "../assets/test1.jpg";
import test3 from "../assets/test3.jpg";
import CustomHelmet from "../components/seo/CustomHelmet";
import { useInView } from "react-intersection-observer";
import clsx from "clsx";
import { useLang } from "../translations/i18n";
import icoarrowleft from "../assets/ico-arrowleft.svg";
import icoarrowright from "../assets/ico-arrowright.svg";
import GoBack from "../components/navigation/GoBack";
import { RUTAS } from "../constants/routes.constants";
import TranslatedLink from "../components/navigation/TranslatedLink";

function Woon({ intl }) {
  const lang = useLang();
  const { ref, inView /*, entry*/ } = useInView({
    /* Optional options */
    threshold: 0,
    delay: 1500,
    triggerOnce: true,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <CustomHelmet
        title={intl.formatMessage({ id: "WOON.TITLESEO" }) || ""}
        description={intl.formatMessage({ id: "WOON.DESCRIPTIONSEO" }) || ""}
      />
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 header-woon p-5">
            <GoBack />
            <h1 className="apercubold mb-0">Woon</h1>
            <h2>
              <div
                className="cooperhewitt"
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage({ id: "WOON.SUBTITLE" }),
                }}
              />
            </h2>
          </div>
        </div>
      </div>
      <div className="container px-0">
        <div className="row">
          <div className="col-12 col-lg-6 my-4 order-2 order-lg-1">
            <div className="main-woon h-100 text-center py-4"></div>
          </div>
          <div className="col-12 col-lg-6 my-4 px-4 colorgrey order-1 order-lg-2">
            <h1 className="apercubold">
              Woon<sup>&reg;</sup>
            </h1>
            <div
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage({ id: "WOON.TEXT1" }),
              }}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12 d-flex justify-content-between my-4">
            <TranslatedLink to={RUTAS.WOODLE}>
              <button className="btnoutline">
                <img src={icoarrowleft} className="icoarrow" /> Woodle
                <sup>&reg;</sup>
              </button>
            </TranslatedLink>
            <TranslatedLink to={RUTAS.WOORIGIN}>
              <button className="btnoutline">
                Woorigin<sup>&reg;</sup>{" "}
                <img src={icoarrowright} className="icoarrow" />
              </button>
            </TranslatedLink>
          </div>
        </div>
      </div>
    </>
  );
}

export default injectIntl(Woon);
