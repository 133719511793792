import React from "react";
import CookieConsent from "react-cookie-consent";
import { useLang } from "../translations/i18n";

function Cookie() {
  const lang = useLang();
  const url =
    lang === "fr"
      ? "/fr/politique-de-cookies"
      : lang === "en"
      ? "/en/cookies-policy"
      : lang == "ca"
      ? "/ca/politica-cookies"
      : "politica-cookies";

  return (
    <div>
      <CookieConsent
        location="bottom"
        buttonText="Accept"
        declineButtonText="Decline"
        cookieName="userAcceptedCookies"
        style={{
          background: "#6a5045",
          color: "white",
          position: "fixed",
          bottom: "0",
          width: "100%",
          left: "0",
          zIndex: "999",
          fontFamily: "cooperhewitt"
        }}
        buttonStyle={{ background: "#cfc5bf", fontSize: "13px" }}
        declineButtonStyle={{
          background: "white",
          fontSize: "13px",
          color: "#535353",
        }}
        expires={150}
      >
        This website uses cookies to enhance the user experience.{" "}
        <span style={{ fontSize: "14px", fontWeight: "bold" }}>
          Read our <a href={url}>cookie policy</a> for more information.
        </span>
      </CookieConsent>
    </div>
  );
}

export default Cookie;
