import React, { useState, useEffect } from "react";
import { injectIntl } from "react-intl";
import CustomHelmet from "../components/seo/CustomHelmet";
import { useInView } from "react-intersection-observer";
import clsx from "clsx";
import { useLocation } from "react-router";
import { useLang } from "../translations/i18n";
import elem1 from "../assets/resourcegallery/elem1.png";
import { Link } from "react-router-dom";
import { SERVER_URL } from "../utils";
import { useMediaAperance } from "../hooks/useMediaAperance";
import { Modal } from "react-bootstrap";
import Page404 from "./Page404";

const GridElem = ({
  title,
  subtitle,
  date,
  link,
  image1,
  image2,
  url,
  id,
  lang,
}) => {
  const [showModal, setShowModal] = useState(false);

  const handleCardClick = () => {
    if (link) {
      window.open(link, "_blank");
    } else if (image2) {
      setShowModal(true);
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div className="col-12 col-lg-3 my-3">
      <div className="bgbrown px-1 py-1 br20 h-100" onClick={handleCardClick}>
        <div>
          <div className="mb-2 bgwhite containerimggallery br15">
            <img
              src={image1}
              className="imggallery"
              style={{ cursor: "pointer" }}
            />
          </div>
          <h1 className="mx-2 mb-0" style={{ fontSize: "18px" }}>
            {title}
          </h1>
          <h6 className="mx-2 mb-0">{subtitle}</h6>
          <div className="mx-2 mb-0 datefont">{formatDate(date, lang)}</div>
        </div>
      </div>

      <Modal show={showModal} onHide={closeModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img src={image2} alt="ModalImage" className="img-fluid" />
        </Modal.Body>
      </Modal>
    </div>
  );
};

const formatDate = (date, lang) => {
  if (date) {
    return new Intl.DateTimeFormat(lang, {
      weekday: "long",
      day: "numeric",
      month: "long",
      year: "numeric",
    }).format(new Date(date));
  }
};

function News({ intl }) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [NotFound, setNotFound] = useState(false);

  const lang = useLang();
  const { ref, inView /*, entry*/ } = useInView({
    /* Optional options */
    threshold: 0,
    delay: 1500,
    triggerOnce: true,
  });

  const mediaTitle = window.location.pathname.split("/")[2];
  const queryParam = window.location.search;
  localStorage.setItem("mediaTitle", mediaTitle);
  localStorage.setItem("queryParam", queryParam);

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const id = params?.entries()?.next()?.value
    ? params?.entries()?.next()?.value[0]
    : undefined;

  const { handleFetchMediaAperance } = useMediaAperance();

  useEffect(() => {
    window.scrollTo(0, 0);
    if (id === undefined) {
      setNotFound(true);
      return;
    }
    const asyncFetch = async () => {
      const data = await handleFetchMediaAperance({ setLoading });
      const filteredData = data.filter((d) => d.media === id);
      if (filteredData.length === 0) {
        setNotFound(true);
        return;
      }
      setData(filteredData);
    };
    asyncFetch();
  }, [id]);

  return (
    <>
      <CustomHelmet
        title={intl.formatMessage({ id: "MEDIAAPPERANCES.TITLESEO" }) || ""}
        description={
          intl.formatMessage({ id: "MEDIAAPPERANCES.DESCRIPTIONSEO" }) || ""
        }
      />
      {NotFound ? (
        <Page404 />
      ) : (
        <div className="container my-5">
          <div className="row">
            <div className="col-12 text-center py-5">
              <div>
                <h1>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: intl.formatMessage({
                        id: "MEDIAAPPERANCES.TITLE",
                      }),
                    }}
                  />
                </h1>
                <p>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: intl.formatMessage({
                        id: "MEDIAAPPERANCES.SUBTITLE",
                      }),
                    }}
                  />
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            {data?.map((elem) => (
              <GridElem
                title={elem.title && elem.title[lang]}
                subtitle={elem.subtitle && elem.subtitle[lang]}
                link={elem.link}
                date={elem.date}
                image1={`${SERVER_URL}/${elem?.filesURLs[0]}`}
                image2={`${SERVER_URL}/${elem?.filesURLs[1]}`}
                url={`${
                  intl.formatMessage({ id: "MEDIAAPERANCESDETAIL.FOLDER" }) ||
                  ""
                }/${
                  elem.title &&
                  elem.title[lang]
                    ?.replace(/\s+/g, "-")
                    .replace(/[^\w\s-]/g, "")
                    .replace(/-+/g, "-")
                    .toLowerCase()
                }?${elem._id}`}
                id={elem._id}
                lang={lang}
              />
            ))}
          </div>
        </div>
      )}
    </>
  );
}

export default injectIntl(News);
