/* eslint-disable jsx-a11y/anchor-is-valid */
import "./App.css";
import { FormattedMessage, injectIntl } from "react-intl";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useState, useEffect, useRef, useContext } from "react";
import clsx from "clsx";
import { Link, Outlet, useLocation } from "react-router-dom";
import logo from "./assets/logo.svg";
import { useTexts } from "./hooks/useTexts";
import {
  setLanguage,
  setLanguageHome,
  getConfig,
  useLang,
} from "./translations/i18n";

import { RUTAS } from "./constants/routes.constants";
import TranslatedLink from "./components/navigation/TranslatedLink";
import ScrollToTop from "./components/ScrollToTop";
import {
  CookieConsentBanner,
  triggerCookieConsentBanner,
} from "@porscheofficial/cookie-consent-banner-react";

import Sub from "./components/navigation/Sub";
import icolinkedinfooter from "./assets/ico-linkedin-footer.svg";
import icoinstagramfooter from "./assets/ico-instagram-footer.svg";
import icoer from "./assets/ico-er.png";
import icopefc from "./assets/ico-pefc.png";
import icofsc from "./assets/ico-fsc.png";
import icoinnovative from "./assets/ico-innovative.png";
import matsos from "./assets/matsos-top.png";
import matsosfooter from "./assets/matsos-footer.png";
import Cookie from "./components/Cookie";
import { FaMapMarkerAlt, FaEnvelopeOpenText, FaPhoneAlt } from "react-icons/fa";
import icoloc from "./assets/footer/icoloc.png";
import icomail from "./assets/footer/icomail.png";
import icophone from "./assets/footer/icophone.png";
import SubMenuContext from "./components/context/SubMenuContext";
import { FaGlobe } from "react-icons/fa";

/*function getEmptyReceiver() {
	return {
		email: '',
		role: 'receiver'
	}
}*/

const Top = ({ handleClick, menuOpen, pathname, parentRef }) => {
  const [activeMenuItem, setActiveMenuItem] = useState(null);
  const activeMenuItemRef = useRef(null);
  const [languageMenu, setLanguageMenu] = useState(false);

  const { updateMenuPosition, updateButtonPositions, buttonRefs } =
    useContext(SubMenuContext);

  useEffect(() => {
    parentRef = activeMenuItemRef;
  }, [activeMenuItemRef]);

  useEffect(() => {
    const handleLoad = () => {
      updateMenuPosition(activeMenuItemRef.current);
    };
    const handleResize = () => {
      updateMenuPosition(activeMenuItemRef.current);
    };
    updateMenuPosition(activeMenuItemRef.current);
    window.addEventListener("load", handleLoad);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("load", handleLoad);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    updateButtonPositions();
    window.addEventListener("resize", updateButtonPositions);
    return () => {
      window.removeEventListener("resize", updateButtonPositions);
    };
  }, []);

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light cooperhewittbold">
        <div className="container-fluid">
          <TranslatedLink to={RUTAS.BASE}>
            <img src={logo} alt="Pujolasos" className="logo" />
          </TranslatedLink>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={(e) => handleClick(e, menuOpen ? false : true, 0)}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="w-100">
            <div className="d-flex flex-row-reverse">
              <div className="ico-rrss-top">
                <a
                  href="https://www.linkedin.com/company/pujolasos-woodworking/"
                  target="blank"
                >
                  <img src={icolinkedinfooter} />
                </a>
                <a
                  href="https://www.instagram.com/pujolasos_packaging/"
                  target="blank"
                >
                  <img src={icoinstagramfooter} />
                </a>
              </div>
              <div className="position-relative">
                <button
                  className="btnoutline"
                  onClick={() => setLanguageMenu(!languageMenu)}
                >
                  <FaGlobe />
                </button>
                {languageMenu && (
                  <div className="languages position-absolute">
                    <div onClick={() => setLanguage("es", pathname)}>ES</div>
                    <div onClick={() => setLanguage("en", pathname)}>EN</div>
                    <div onClick={() => setLanguage("ca", pathname)}>CA</div>
                    <div onClick={() => setLanguage("fr", pathname)}>FR</div>
                  </div>
                )}
              </div>
            </div>
            <div
              className={clsx("navbar-collapse", menuOpen ? "" : " collapse")}
              id="navbarNav"
            >
              <ul className="navbar-nav d-flex justify-content-end w-100">
                <li className="nav-item active" ref={buttonRefs[0]}>
                  <TranslatedLink
                    onClick={(event) => {
                      handleClick(event, 1);
                      setActiveMenuItem(1);
                      activeMenuItemRef.current = event.currentTarget;
                      updateMenuPosition(event.currentTarget);
                    }}
                    ref={activeMenuItem === 1 ? activeMenuItemRef : null}
                    to={RUTAS.PERFUMES}
                    className={clsx(
                      "nav-link",
                      pathname === `${RUTAS.PERFUMES.es}` ||
                        pathname === `${RUTAS.PERFUMES.ca}` ||
                        pathname === `${RUTAS.PERFUMES.en}`
                        ? " cooperhewittbold"
                        : ""
                    )}
                  >
                    <FormattedMessage id="MENU.SOLUTIONS" />
                  </TranslatedLink>
                </li>
                {/* <li>
                  <a href="https://matsos.xyz" target="blank">
                    <img src={matsos} className="icomatsos" style={{'width':'100px;'}} />
                  </a>
                </li> */}
                <li className="nav-item" ref={buttonRefs[1]}>
                  <TranslatedLink
                    onClick={(event) => {
                      handleClick(event, 2);
                      setActiveMenuItem(2);
                      activeMenuItemRef.current = event.currentTarget;
                      updateMenuPosition(event.currentTarget);
                    }}
                    ref={activeMenuItem === 2 ? activeMenuItemRef : null}
                    to={RUTAS.ABOUT}
                    className={clsx(
                      "nav-link",
                      pathname === `${RUTAS.ABOUT.es}` ||
                        pathname === `${RUTAS.ABOUT.ca}` ||
                        pathname === `${RUTAS.ABOUT.en}`
                        ? " cooperhewittbold"
                        : ""
                    )}
                  >
                    <FormattedMessage id="MENU.ABOUT" />
                  </TranslatedLink>
                </li>
                <li className="nav-item" ref={buttonRefs[2]}>
                  <TranslatedLink
                    onClick={(event) => {
                      handleClick(event, 3);
                      setActiveMenuItem(3);
                      activeMenuItemRef.current = event.currentTarget;
                      updateMenuPosition(event.currentTarget);
                    }}
                    ref={activeMenuItem === 3 ? activeMenuItemRef : null}
                    to={RUTAS.SUSTAINABILITY}
                    className={clsx(
                      "nav-link",
                      pathname === `${RUTAS.SUSTAINABILITY.es}` ||
                        pathname === `${RUTAS.SUSTAINABILITY.ca}` ||
                        pathname === `${RUTAS.SUSTAINABILITY.en}`
                        ? " cooperhewittbold"
                        : ""
                    )}
                  >
                    <FormattedMessage id="MENU.SUSTAINABLECOMMITMENT" />
                  </TranslatedLink>
                </li>
                <li className="nav-item" ref={buttonRefs[3]}>
                  <TranslatedLink
                    onClick={(event) => {
                      handleClick(event, 4);
                      setActiveMenuItem(4);
                      activeMenuItemRef.current = event.currentTarget;
                      updateMenuPosition(event.currentTarget);
                    }}
                    ref={activeMenuItem === 4 ? activeMenuItemRef : null}
                    to={RUTAS.PRESS}
                    className={clsx(
                      "nav-link",
                      pathname === `${RUTAS.PRESS.es}` ||
                        pathname === `${RUTAS.PRESS.ca}` ||
                        pathname === `${RUTAS.PRESS.en}`
                        ? " cooperhewittbold"
                        : ""
                    )}
                  >
                    <FormattedMessage id="MENU.PRESS" />
                  </TranslatedLink>
                </li>
                <li className="nav-item">
                  <TranslatedLink
                    onClick={(event) => {
                      handleClick(event, 5);
                      setActiveMenuItem(5);
                      activeMenuItemRef.current = event.currentTarget;
                      updateMenuPosition(event.currentTarget);
                    }}
                    ref={activeMenuItem === 5 ? activeMenuItemRef : null}
                    to={RUTAS.CONTACT}
                    className={clsx(
                      "nav-link",
                      pathname === `${RUTAS.CONTACT.es}` ||
                        pathname === `${RUTAS.CONTACT.ca}` ||
                        pathname === `${RUTAS.CONTACT.en}`
                        ? " cooperhewittbold"
                        : ""
                    )}
                  >
                    <FormattedMessage id="MENU.CONTACT" />
                  </TranslatedLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

function loadTagManager(cookieValue) {}

function App({ intl }) {
  const { pathname } = useLocation();
  const lang = useLang();
  const [menuOpen, setMenuOpen] = useState(false);
  const [bloque, setBloque] = useState(0);
  // const { handleFetchText } = useTexts();
  const [acceptedCategories, setAcceptedCategories] = useState([]);
  const cookieValue = document.cookie;
  const [showNavbar, setShowNavbar] = useState(true);
  const lastScrollY = useRef(0);

  const controlNavbar = () => {
    if (typeof window !== "undefined") {
      if (window.scrollY > lastScrollY.current && window.scrollY > 50) {
        setShowNavbar(false);
      } else {
        setShowNavbar(true);
      }
      if (window.scrollY < lastScrollY.current && window.scrollY < 50) {
        setShowNavbar(false);
        setTimeout(() => {
          setShowNavbar(true);
        }, 0.005);
      }
      lastScrollY.current = window.scrollY;
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", controlNavbar);
    return () => {
      window.removeEventListener("scroll", controlNavbar);
    };
  }, []);

  document
    .querySelector(".react-cookie")
    ?.shadowRoot?.querySelector(".cc_settings")
    ?.setAttribute("style", "padding-bottom: 0.5rem");
  document
    .querySelector(".react-cookie")
    ?.shadowRoot?.querySelector(".cc_settings_description")
    ?.setAttribute("style", "display: none");
  document
    .querySelector(".react-cookie")
    ?.shadowRoot?.querySelector(".cc_text")
    ?.setAttribute("style", "padding-bottom: 0.5rem; padding-top: 0.5rem");
  document
    .querySelector(".react-cookie")
    ?.shadowRoot?.querySelectorAll("button")
    .forEach((elem) =>
      elem.setAttribute("style", "font-size: 14px; padding: 0.5rem 1.5rem")
    );

  useEffect(() => {
    const cookies = cookieValue
      .split("; ")
      .find((row) => row.startsWith("cookies_accepted_categories="));
    if (cookies === undefined)
      triggerCookieConsentBanner({ showDetails: true });
    else if (cookies.includes("analytics") || cookies.includes("marketing"))
      loadTagManager(cookies);
  }, [cookieValue]);

  function handleClick(e, status, flag) {
    e.stopPropagation();
    if (status) {
      setMenuOpen(true);
      const menuItemElement = e.currentTarget;
      if (flag === 0) {
        setMenuScrollLeft(43);
      } else if (flag === 1) {
        setMenuScrollLeft(0);
      } else {
        const menuPosition = menuItemElement.getBoundingClientRect().left;
        setMenuScrollLeft(menuPosition + 9);
      }
    } else {
      setMenuOpen(false);
    }
  }

  React.useEffect(() => {
    // eslint-disable-next-line
    const [_, url_lang] = pathname.split("/");
    if (url_lang === "ca" && lang !== "ca") setLanguage("ca", pathname);
    else if (url_lang === "en" && lang !== "en") setLanguage("en", pathname);
    else if (url_lang === "fr" && lang !== "fr") setLanguage("fr", pathname);
    else if (
      url_lang !== "ca" &&
      url_lang !== "en" &&
      url_lang !== "fr" &&
      lang !== "es"
    )
      setLanguage("es", pathname);
  }, [lang, pathname]);

  // React.useEffect(() => {
  //   const asyncFetchText = async () => {
  //     const { data } = await handleFetchText({
  //       id: "63d8da146efb29c596a4657f",
  //     });
  //     setText(data);
  //   };
  //   asyncFetchText();
  //   // eslint-disable-next-line
  // }, [pathname]);

  const handleChange = (element) => (event) => {
    setLanguage(element, pathname);
  };

  let copyright = (
    <>
      Pujolasos
      <br />
      <FormattedMessage id="FOOTER.TODOSLOSDERECHOS" />
    </>
  );

  const [menuScrollLeft, setMenuScrollLeft] = useState(0);
  const parentRef = useRef(null);

  return (
    <div className="App" onClick={(e) => handleClick(e, false)}>
      <header>
        <div
          className={`container-fluid navigation p-0 ${
            showNavbar ? "navbar-visible" : "navbar-hidden"
          } ${window.scrollY > 200 ? "navbar-fixed" : "navbar-relative"}`}
        >
          <Top
            handleClick={handleClick}
            menuOpen={menuOpen}
            pathname={pathname}
            setMenuScrollLeft={setMenuScrollLeft}
            parentRef={parentRef}
            etiqlanguage={intl.formatMessage({ id: "MENU.LANGUAGE" })}
          />
          <Sub
            pathname={pathname}
            menuScrollLeft={menuScrollLeft}
            parentRef={parentRef}
          />
        </div>
        <Cookie />
      </header>

      <Outlet />

      <ScrollToTop />

      <footer className="footer">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-3 px-3 py-5">
              <div>
                <TranslatedLink
                  onClick={(event) => handleClick(event, 1, 1)}
                  to={RUTAS.PERFUMES}
                >
                  <FormattedMessage id="MENU.SOLUTIONS" />
                </TranslatedLink>
              </div>
              <div>
                <TranslatedLink
                  onClick={(event) => handleClick(event, 2, 1)}
                  to={RUTAS.ABOUT}
                >
                  <FormattedMessage id="MENU.ABOUT" />
                </TranslatedLink>
              </div>
              <div>
                <TranslatedLink
                  onClick={(event) => handleClick(event, 3, 1)}
                  to={RUTAS.SUSTAINABILITY}
                >
                  <FormattedMessage id="MENU.SUSTAINABLECOMMITMENT" />
                </TranslatedLink>
              </div>
              <div>
                <TranslatedLink
                  onClick={(event) => handleClick(event, 4, 1)}
                  to={RUTAS.PRESS}
                >
                  <FormattedMessage id="MENU.PRESS" />
                </TranslatedLink>
              </div>
              <div>
                <TranslatedLink
                  onClick={(event) => handleClick(event, 5, 1)}
                  to={RUTAS.CONTACT}
                >
                  <FormattedMessage id="MENU.CONTACT" />
                </TranslatedLink>
              </div>
              <div className="ico-footer">
                <a
                  href="https://www.linkedin.com/company/pujolasos-woodworking/"
                  target="blank"
                >
                  <img src={icolinkedinfooter} />
                </a>
                <a
                  href="https://www.instagram.com/pujolasos_packaging/"
                  target="blank"
                >
                  <img src={icoinstagramfooter} />
                </a>
              </div>
            </div>
            <div className="col-12 col-md-3 px-3 py-5">
              <div className="mb-4 position-relative">
                <img
                  src={icoloc}
                  className="icofooter"
                  style={{ marginTop: "0px" }}
                />
                <div className="textfooter">
                  Pol. Ind. Av. Fontsanta s/n
                  <br />
                  08572 Sant Pere de Torelló
                  <br />
                  Barcelona, España
                </div>
              </div>
              <div className="mb-4 position-relative">
                <img
                  src={icomail}
                  className="icofooter"
                  style={{ marginTop: "-27px" }}
                />
                <div className="textfooter">comercial@pujolasos.com</div>
              </div>
              <div className="mb-4 position-relative">
                <img src={icophone} className="icofooter" />
                <div className="textfooter">
                  Tel.: (+34) 93 858 44 51
                  <br />
                  Fax.: (+34) 93 850 90 58
                </div>
              </div>
            </div>
            <div className="col-12 col-md-3 px-3 py-5">
              <div>
                <TranslatedLink
                  onClick={(event) => handleClick(event, 5)}
                  to={RUTAS.LEGAL}
                >
                  <FormattedMessage id="FOOTER.LEGALNOTICE" />
                </TranslatedLink>
              </div>
              <div>
                <TranslatedLink
                  onClick={(event) => handleClick(event, 5)}
                  to={RUTAS.INFORMATIONSYSTEM}
                >
                  <FormattedMessage id="FOOTER.INTERNINFORMATIONSYSTEM" />
                </TranslatedLink>
              </div>
              <div>
                <TranslatedLink
                  onClick={(event) => handleClick(event, 5)}
                  to={RUTAS.PRIVACIDAD}
                >
                  <FormattedMessage id="FOOTER.PRIVACYPOLICY" />
                </TranslatedLink>
              </div>
              <div>
                <TranslatedLink
                  onClick={(event) => handleClick(event, 5)}
                  to={RUTAS.PRIVACIDADRRSS}
                >
                  <FormattedMessage id="FOOTER.SOCIALNETWORK" />
                </TranslatedLink>
              </div>
              <div>
                <TranslatedLink
                  onClick={(event) => handleClick(event, 5)}
                  to={RUTAS.COOKIES}
                >
                  <FormattedMessage id="FOOTER.COOKIEPOLICY" />
                </TranslatedLink>
              </div>
              <div className="d-flex">
                <img src={icoer} className="icoseal mx-1" />
                <img src={icopefc} className="icoseal mx-1" />
                <img src={icoinnovative} className="icoseal mx-1" />
                <img src={icofsc} className="icoseal mx-1" />
              </div>
            </div>
            <div className="col-12 col-md-3 d-flex px-3 py-5">
              <a href="https://matsos.xyz" target="blank">
                <img
                  src={matsosfooter}
                  className="icomatsos"
                  style={{ width: "170px" }}
                />
              </a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default injectIntl(App);
