import React from "react";

const SubMenuContext = React.createContext({
  menuScrollLeft: 0,
  updateMenuPosition: () => {},
  buttonPositions: [],
  updateButtonPositions: () => {},
});

export default SubMenuContext;
