import axios from 'axios'
import { API } from '../../utils/index'
const instance = axios.create()


export const getResourceGallerys = async () => {
	const _endpoint = 'gallery'
	const URL = `${API}/${_endpoint}`
	return instance.get(URL)
}


export const getResourceGallery = async (id) => {
	const _endpoint = `gallery/${id}`
	const URL = `${API}/${_endpoint}`

	return instance.get(URL)
}