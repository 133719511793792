import React, { useState, useEffect } from "react";
import { useLocation } from "react-router";
import { injectIntl } from "react-intl";
import CustomHelmet from "../components/seo/CustomHelmet";
import { useInView } from "react-intersection-observer";
import { useLang } from "../translations/i18n";
import { useNews } from "../hooks/useNews";
import { SERVER_URL } from "../utils";
import Page404 from "./Page404";
import CopyIcon from "../assets/copy-icon.svg";

function NewsDetail({ intl }) {
  const [loading, setLoading] = useState(false);
  const [NotFound, setNotFound] = useState(false);
  const lang = useLang();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const { handleFetchNew } = useNews();

  const id = params?.entries()?.next()?.value
    ? params?.entries()?.next()?.value[0]
    : undefined;

  const { ref, inView /*, entry*/ } = useInView({
    /* Optional options */
    threshold: 0,
    delay: 1500,
    triggerOnce: true,
  });
  const [currentNew, setCurrentNew] = useState(null);

  const mediaTitle = window.location.pathname.split("/")[2];
  const queryParam = window.location.search;
  localStorage.setItem("mediaTitle", mediaTitle);
  localStorage.setItem("queryParam", queryParam);

  useEffect(() => {
    if (id === undefined) {
      setNotFound(true);
      return;
    }
    const asyncFetch = async () => {
      try {
        const data = await handleFetchNew({ id, setLoading });
        console.log("Fetched data:", data);
        if (data.status === 404) {
          setNotFound(true);
        } else {
          setCurrentNew(data.data);
        }
      } catch (error) {
        console.error("Error fetching news:", error);
        setNotFound(true);
      }
    };
    asyncFetch();
  }, [id]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const formatDate = (date, lang) => {
    if (date) {
      return new Intl.DateTimeFormat(lang, {
        weekday: "long",
        day: "numeric",
        month: "long",
        year: "numeric",
      }).format(new Date(date));
    }
  };

  if (NotFound) {
    return <Page404 />;
  }

  return (
    <>
      <CustomHelmet
        title={currentNew?.title && currentNew?.title[lang]}
        description={
          intl.formatMessage({ id: "MEDIAAPPERANCES.DESCRIPTIONSEO" }) || ""
        }
      />
      <div className="container my-5">
        <div className="row">
          <div className="col-12">
            {currentNew && currentNew.filesURLs && currentNew.filesURLs[0] && (
              <img
                src={`${SERVER_URL}/${currentNew.filesURLs[0]}`}
                className="img-detailnews"
              />
            )}
            <h1 className="mt-4">
              {currentNew?.title && currentNew?.title[lang]}
            </h1>
            {currentNew?.subtitle && (
              <h3 className="mb-4">{currentNew?.subtitle[lang]}</h3>
            )}
            <div className="cooperhewittbold">
              {formatDate(currentNew?.date, lang)}
            </div>
            <div
              dangerouslySetInnerHTML={{
                __html:
                  (currentNew?.content && currentNew?.content[lang]) || "",
              }}
            />
            {currentNew?.link && (
              <a href={currentNew?.link} target="blank">
                <img
                  src={CopyIcon}
                  alt="Copy Icon"
                  style={{ width: "30px", height: "30px" }}
                />
              </a>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default injectIntl(NewsDetail);
