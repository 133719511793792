import React, { useState, useEffect } from "react";
import { injectIntl } from "react-intl";
import CustomHelmet from "../components/seo/CustomHelmet";
import { useInView } from "react-intersection-observer";
import clsx from "clsx";
import { useLang } from "../translations/i18n";
import press1 from "../assets/apar-es.png";
import press2 from "../assets/notas-es.png";
import press3 from "../assets/act-es.png";
import press1en from "../assets/apar-en.png";
import press2en from "../assets/notas-en.png";
import press3en from "../assets/act-en.png";
import press1ca from "../assets/apar-ca.png";
import press2ca from "../assets/notas-ca.png";
import press3ca from "../assets/act-ca.png";
import press1fr from "../assets/apar-fr.png";
import press2fr from "../assets/notas-fr.png";
import press3fr from "../assets/act-fr.png";
import { RUTAS } from "../constants/routes.constants";
import TranslatedLink from "../components/navigation/TranslatedLink";

function Press({ intl }) {
  const lang = useLang();
  const { ref, inView /*, entry*/ } = useInView({
    /* Optional options */
    threshold: 0,
    delay: 1500,
    triggerOnce: true,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <CustomHelmet
        title={intl.formatMessage({ id: "PRESS.TITLESEO" }) || ""}
        description={intl.formatMessage({ id: "PRESS.DESCRIPTIONSEO" }) || ""}
      />
      <div className="container my-5">
        <div className="row">
          <div className="col-12 text-center py-5 cooperhewitt">
            <div>
              <h1>
                <div
                  dangerouslySetInnerHTML={{
                    __html: intl.formatMessage({ id: "PRESS.TITLE" }),
                  }}
                />
              </h1>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-lg-4 my-3">
            <TranslatedLink to={RUTAS.MEDIAAPPERANCES}>
              <div className="mb-3">
                <img
                  src={
                    lang === "fr"
                      ? press1fr
                      : lang === "ca"
                      ? press1ca
                      : lang === "en"
                      ? press1en
                      : press1
                  }
                  className="img-fluid"
                />
              </div>
              <h4>
                <div
                  dangerouslySetInnerHTML={{
                    __html: intl.formatMessage({ id: "PRESS.MEDIAAPPERANCES" }),
                  }}
                />
              </h4>
              <p>
                <div
                  dangerouslySetInnerHTML={{
                    __html: intl.formatMessage({
                      id: "PRESS.TEXTMEDIAAPPERANCES",
                    }),
                  }}
                />
              </p>
            </TranslatedLink>
          </div>
          <div className="col-12 col-lg-4 my-3">
            <TranslatedLink to={RUTAS.PRESSNEWS}>
              <div className="mb-3">
                <img
                  src={
                    lang === "fr"
                      ? press2fr
                      : lang === "ca"
                      ? press2ca
                      : lang === "en"
                      ? press2en
                      : press2
                  }
                  className="img-fluid"
                />
              </div>
              <h4>
                <div
                  dangerouslySetInnerHTML={{
                    __html: intl.formatMessage({ id: "PRESS.PRESSRELEASE" }),
                  }}
                />
              </h4>
              <p>
                <div
                  dangerouslySetInnerHTML={{
                    __html: intl.formatMessage({
                      id: "PRESS.TEXTPRESSRELEASE",
                    }),
                  }}
                />
              </p>
            </TranslatedLink>
          </div>
          <div className="col-12 col-lg-4 my-3">
            <TranslatedLink to={RUTAS.NEWS}>
              <div className="mb-3">
                <img
                  src={
                    lang === "fr"
                      ? press3fr
                      : lang === "ca"
                      ? press3ca
                      : lang === "en"
                      ? press3en
                      : press3
                  }
                  className="img-fluid"
                />
              </div>
              <h4>
                <div
                  dangerouslySetInnerHTML={{
                    __html: intl.formatMessage({ id: "PRESS.NEWS" }),
                  }}
                />
              </h4>
              <p>
                <div
                  dangerouslySetInnerHTML={{
                    __html: intl.formatMessage({ id: "PRESS.TEXTNEWS" }),
                  }}
                />
              </p>
            </TranslatedLink>
          </div>
          {/* <div className="col-12 col-lg-3 my-3">
            <TranslatedLink to={RUTAS.RESOURCEGALLERY}>
              <div className="mb-3">
                <img src={press4} className="img-fluid" />
              </div>
              <h4>
                <div
                  dangerouslySetInnerHTML={{
                    __html: intl.formatMessage({ id: "PRESS.RESOURCEGALLERY" }),
                  }}
                />
              </h4>
              <p>
                <div
                  dangerouslySetInnerHTML={{
                    __html: intl.formatMessage({
                      id: "PRESS.TEXTRESOURCEGALLERY",
                    }),
                  }}
                />
              </p>
            </TranslatedLink>
          </div> */}
        </div>
      </div>
    </>
  );
}

export default injectIntl(Press);
