import React, { useState, useEffect } from "react";
import { injectIntl } from "react-intl";
import CustomHelmet from "../components/seo/CustomHelmet";
import { useInView } from "react-intersection-observer";
import clsx from "clsx";
import { useLang } from "../translations/i18n";
import logosustainability from "../assets/logo-sustainability.svg";
import icosostenibilidadpoliticacalidad from "../assets/ico-sostenibilidadpoliticacalidad.png";
import icosostenibilidadpoliticacompromiso from "../assets/ico-sostenibilidadpoliticacompromiso.svg";
import icomedallaplata from "../assets/ico-medallaplata.svg";
import videoheadersostenibilidad from "../assets/videoheadersostenibilidad.webm";
import ecovadis from "../assets/ecovadis.png";
import pdfpoliticacalidad from "../assets/pdf/politica_calidad_en.pdf";
import pdfpoliticacompromiso from "../assets/pdf/politica_compromiso_en.pdf";

function Sustainability({ intl }) {
  const lang = useLang();
  const { ref, inView /*, entry*/ } = useInView({
    /* Optional options */
    threshold: 0,
    delay: 1500,
    triggerOnce: true,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <CustomHelmet
        title={intl.formatMessage({ id: "SUSTAINABILITY.TITLESEO" }) || ""}
        description={
          intl.formatMessage({ id: "SUSTAINABILITY.DESCRIPTIONSEO" }) || ""
        }
      />
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 px-0">
            <video loop autoPlay muted playsInline className="img-fluid">
              <source src={videoheadersostenibilidad} type="video/webm" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </div>
      <div className="container-fluid my-0 my-lg-3">
        <div className="row">
          <div className="col-12 bg-sustainability2"></div>
        </div>
      </div>
      <div className="container my-0 my-lg-3">
        <div className="row">
          <div className="col-12 col-md-6 my-3 my-lg-0 bg-sostenibilidadpartner"></div>
          <div className="col-12 col-md-6 px-0 px-lg-3 py-0 py-lg-0">
            <div className="bglightgrey3 d-flex align-items-center  p-5 h-100">
              <div
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage({ id: "SUSTAINABILITY.TEXT1" }),
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="container my-0 my-lg-3">
        <div className="row">
          <div className="col-12 col-md-6 bglightbrown p-5 order-2 order-lg-1 d-flex align-items-center ">
            <div
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage({ id: "SUSTAINABILITY.TEXT2" }),
              }}
            />
          </div>
          <div className="col-12 col-md-6 px-0 px-lg-3 py-3 py-lg-0 order-1 order-lg-2">
            <div className=" bg-sostenibilidadpartner2 h-100"></div>
          </div>
        </div>
      </div>
      <div className="container my-0 my-lg-3">
        <div className="row">
          <div className="col-12 col-md-5 my-3 my-lg-0 bg-sostenibilidadpartner3"></div>
          <div className="col-12 col-md-7 px-0 px-lg-3 py-0 py-lg-0 d-flex align-items-center ">
            <div className="bglightgrey  p-5 h-100">
              <div
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage({ id: "SUSTAINABILITY.TEXT3" }),
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="container my-0 my-lg-3">
        <div className="row">
          <div className="col-12 text-center py-4 subtitle">
            <div
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage({
                  id: "SUSTAINABILITY.TEXTTHEORGANIZATION",
                }),
              }}
            />
          </div>
          <div className="col-12 col-lg-4 text-center my-4">
            <div className="icosostconainer">
              <a href={pdfpoliticacalidad} target="_blank">
                <img
                  src={icosostenibilidadpoliticacalidad}
                  className="imgecovadis"
                />
              </a>
            </div>
            <div
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage({
                  id: "SUSTAINABILITY.QUALITYPOLICY",
                }),
              }}
            />
          </div>
          <div className="col-12 col-lg-4 text-center my-4">
            <div className="icosostconainer">
              <a href={pdfpoliticacompromiso} target="_blank">
                <img src={icosostenibilidadpoliticacompromiso} />
              </a>
            </div>
            <div
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage({
                  id: "SUSTAINABILITY.COMMITMENTPOLICY",
                }),
              }}
            />
          </div>
          <div className="col-12 col-lg-4 text-center my-4">
            <div className="icosostconainer">
              <img src={ecovadis} className="imgecovadis" />
            </div>
            <div
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage({
                  id: "SUSTAINABILITY.SILVERMEDAL",
                }),
              }}
            />
          </div>
        </div>
      </div>
      <div className="container-fluid my-0 my-lg-3">
        <div className="row">
          <div className="col-12 col-md-8">
            <div className="bg-sostenibilidadfooter1 h-100"></div>
          </div>
          <div className="col-12 col-md-4">
            <div className="bg-sostenibilidadfooter2 h-100"></div>
          </div>
        </div>
      </div>
    </>
  );
}

export default injectIntl(Sustainability);
