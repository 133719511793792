import React from "react";
import { Link } from "react-router-dom";
import { useLang } from "../../translations/i18n";

function TranslatedLink({ to, children, ...props }) {
  const lang = useLang();

  if (!to) return null;

  return (
    <Link
      style={{ display: "inline-block", position: "relative" }}
      to={to[lang]}
      {...props}
    >
      {children}
    </Link>
  );
}

export default TranslatedLink;
