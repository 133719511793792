import React, { useState, useEffect } from "react";
import { injectIntl } from "react-intl";
import CustomHelmet from "../components/seo/CustomHelmet";
import { useInView } from "react-intersection-observer";
import clsx from "clsx";
import { useLang } from "../translations/i18n";
import elem1 from "../assets/resourcegallery/elem1.png";
import { Link } from "react-router-dom";
import { SERVER_URL } from "../utils";
import { useMedia } from "../hooks/useMedia";

const GridElem = ({ title, subtitle, date, content, image, url, id, lang }) => {
  return (
    <div className="col-12 col-lg-3 my-3">
      <div className="bgbrown px-1 py-1 br20 h-100">
        <Link to={url} state={{ id: id }} title="--" class="--">
          <div className="mb-2 bgwhite containerimggallery br15">
            <img src={image} className="imggallery" />
          </div>
        </Link>
      </div>
    </div>
  );
};

const formatDate = (date, lang) => {
  if (date) {
    return new Intl.DateTimeFormat(lang, {
      weekday: "long",
      day: "numeric",
      month: "long",
      year: "numeric",
    }).format(new Date(date));
  }
};

function News({ intl }) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const lang = useLang();
  const { ref, inView /*, entry*/ } = useInView({
    /* Optional options */
    threshold: 0,
    delay: 1500,
    triggerOnce: true,
  });

  const { handleFetchMedia } = useMedia();

  useEffect(() => {
    window.scrollTo(0, 0);
    const asyncFetch = async () => {
      const data = await handleFetchMedia({ setLoading });
      setData(data);
    };
    asyncFetch();
  }, []);

  return (
    <>
      <CustomHelmet
        title={intl.formatMessage({ id: "MEDIAAPPERANCES.TITLESEO" }) || ""}
        description={
          intl.formatMessage({ id: "MEDIAAPPERANCES.DESCRIPTIONSEO" }) || ""
        }
      />
      <div className="container my-5">
        <div className="row">
          <div className="col-12 text-center py-5">
            <div>
              <h1>
                <div
                  dangerouslySetInnerHTML={{
                    __html: intl.formatMessage({ id: "MEDIAAPPERANCES.TITLE" }),
                  }}
                />
              </h1>
              <p>
                <div
                  dangerouslySetInnerHTML={{
                    __html: intl.formatMessage({
                      id: "MEDIAAPPERANCES.SUBTITLE",
                    }),
                  }}
                />
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          {data &&
            data.map((elem) => (
              <GridElem
                title={elem.title && elem.title[lang]}
                subtitle={elem.subtitle && elem.subtitle[lang]}
                date={elem.date}
                image={`${SERVER_URL}/${elem.filesURLs[0]}`}
                url={`${
                  intl.formatMessage({ id: "MEDIAAPPERANCES.FOLDER" }) || ""
                }/${
                  elem.title &&
                  elem.title[lang]
                    ?.replace(/\s+/g, "-")
                    .replace(/[^\w\s-]/g, "")
                    .replace(/-+/g, "-")
                    .toLowerCase()
                }?${elem._id}`}
                id={elem._id}
                lang={lang}
              />
            ))}
        </div>
      </div>
    </>
  );
}

export default injectIntl(News);
