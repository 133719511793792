import React, { useState, useEffect } from 'react'
import { injectIntl } from 'react-intl'
import test1 from '../assets/test1.jpg'
import test3 from '../assets/test3.jpg'
import CustomHelmet from '../components/seo/CustomHelmet'
import { useInView } from 'react-intersection-observer';
import clsx from 'clsx'
import { useLang } from '../translations/i18n'
import { RUTAS } from '../constants/routes.constants'


function SolutionsHome({ intl }) {
	const lang = useLang()
	const { ref, inView/*, entry*/ } = useInView({
    /* Optional options */
    threshold: 0,
		delay: 1500,
		triggerOnce: true
	});

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	return (<>
		<CustomHelmet
			title={intl.formatMessage({ id: 'SOLUTIONSHOME.TITLESEO' }) || ''}
			description={intl.formatMessage({ id: 'SOLUTIONSHOME.DESCRIPTIONSEO' }) || ''}
		/>
		<div className='container'>
			<div className='row my-3'>
				<div className='col-12 col-lg-8'>
					<div className='bg-homeback1 h-100'></div>
				</div>
				<div className='col-12 col-lg-4 '>
					<div className='bglightgrey3 h-100 f20 p-5'>
						<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod  tempor incididunt ut labore et dolore magna aliqua.</p>
					</div>
				</div>
			</div>
			<div className='row'>
				<div className='col-12 text-center py-5'>
					<h2>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</h2>
				</div>
			</div>
			<div className='row'>
				<div className='col-12 bg-homeback2'>
				</div>
			</div>
		</div>
		<div className="container">
			<div className='row'>
				<div className='col-12 col-lg-6 my-4'>
					<div className='bg-homeback3 h-100'>
					</div>
				</div>
				<div className='col-12 col-lg-6 my-4'>
					<div className='bg-homeback4 h-100'>
					</div>
				</div>
			</div>
		</div>
	</>
  	)
}

export default injectIntl(SolutionsHome)
