import React, { useState, useEffect } from "react";
import { injectIntl } from "react-intl";
import test1 from "../assets/test1.jpg";
import test3 from "../assets/test3.jpg";
import CustomHelmet from "../components/seo/CustomHelmet";
import { useInView } from "react-intersection-observer";
import clsx from "clsx";
import { useLang } from "../translations/i18n";
import videoheaderperfume from "../assets/videoheaderperfume.webm";
import videoourinnovations from "../assets/videoourinnovations.webm";
import { RUTAS } from "../constants/routes.constants";
import { Link } from "react-router-dom";
import TranslatedLink from "../components/navigation/TranslatedLink";

function OurInnovations({ intl }) {
  const lang = useLang();
  const { ref, inView /*, entry*/ } = useInView({
    /* Optional options */
    threshold: 0,
    delay: 1500,
    triggerOnce: true,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <CustomHelmet
        title={intl.formatMessage({ id: "OURINNOVATIONS.TITLESEO" }) || ""}
        description={
          intl.formatMessage({ id: "OURINNOVATIONS.DESCRIPTIONSEO" }) || ""
        }
      />
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 px-0">
            <video loop autoPlay muted playsInline className="img-fluid">
              <source src={videoheaderperfume} type="video/webm" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </div>
      <div className="container px-0">
        <div className="row my-3">
          <div className="col-12 col-lg-6">
            <div className="bg-innovationswoorigin h-100"></div>
          </div>
          <div className="col-12 col-lg-6 ">
            <div className="bglightgrey3 h-100 f20 p-5">
              <div>
                <div className="apercubold">
                  Woorigin<sup>&reg;</sup>
                </div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: intl.formatMessage({
                      id: "OURINNOVATIONS.TEXTWOORIGIN",
                    }),
                  }}
                />
              </div>
              <div>
                <TranslatedLink to={RUTAS.WOORIGIN}>
                  <button className="btnoutline">
                    Woorigin<sup>&reg;</sup>
                  </button>
                </TranslatedLink>
              </div>
            </div>
          </div>
        </div>
        <div className="row my-3">
          <div className="col-12 col-lg-4">
            <div className="bglightgrey h-100 f20 p-5">
              <div>
                <div className="apercubold">
                  Woodle<sup>&reg;</sup>
                </div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: intl.formatMessage({
                      id: "OURINNOVATIONS.TEXTWOODLE",
                    }),
                  }}
                />
                <div>
                  <TranslatedLink to={RUTAS.WOODLE}>
                    <button className="btnoutline">
                      Woodle<sup>&reg;</sup>
                    </button>
                  </TranslatedLink>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-8">
            <div className="bg-innovationswoodle h-100"></div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 px-0">
            <video
              loop
              autoPlay
              muted
              playsInline
              className="img-fluid videoourinnovations"
            >
              <source src={videoourinnovations} type="video/webm" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </div>
      <div className="container px-0">
        <div className="row my-3">
          <div className="col-12 col-lg-5">
            <div className="bg-innovationswoon h-100"></div>
          </div>
          <div className="col-12 col-lg-7">
            <div className="bglightgrey h-100 f20 p-5">
              <div>
                <div className="apercubold">Woon</div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: intl.formatMessage({
                      id: "OURINNOVATIONS.TEXTWOON",
                    }),
                  }}
                />
                <div>
                  <TranslatedLink to={RUTAS.WOON}>
                    <button className="btnoutline">
                      Woon<sup>&reg;</sup>
                    </button>
                  </TranslatedLink>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row my-3">
          <div className="col-12 col-lg-4">
            <div className="bglightgrey h-100 f20 p-5">
              <div>
                <div className="apercubold">
                  Woork<sup>&reg;</sup>
                </div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: intl.formatMessage({
                      id: "OURINNOVATIONS.TEXTWOORK",
                    }),
                  }}
                />
                <div>
                  <TranslatedLink to={RUTAS.WOORK}>
                    <button className="btnoutline">
                      Woork<sup>&reg;</sup>
                    </button>
                  </TranslatedLink>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-8">
            <div className="bg-innovationswoork h-100"></div>
          </div>
        </div>
        <div className="row my-3">
          <div className="col-12 col-lg-8">
            <div className="bg-innovationsprefill h-100"></div>
          </div>
          <div className="col-12 col-lg-4">
            <div className="bglightgrey h-100 f20 p-5">
              <div>
                <div className="apercubold">
                  P-Refill<sup>&reg;</sup>
                </div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: intl.formatMessage({
                      id: "OURINNOVATIONS.TEXTPREFILL",
                    }),
                  }}
                />
                <div>
                  <TranslatedLink to={RUTAS.PREFILL}>
                    <button className="btnoutline">
                      P-Refill<sup>&reg;</sup>
                    </button>
                  </TranslatedLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default injectIntl(OurInnovations);
