import React, { useContext, useState, useEffect } from "react";
import { Carousel } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import TranslatedLink from "./navigation/TranslatedLink";
import { RUTAS } from "../constants/routes.constants";
import { SERVER_URL } from "../utils";
import SubMenuContext from "../components/context/SubMenuContext";
import { getCarrousels } from "../api/carrousel";
import { useLang } from "../translations/i18n";
import { encodeImageURL } from "../utils/helpers";

const DinamicCarrousel = () => {
  const { updateMenuPosition } = useContext(SubMenuContext);
  const [carrousel, setCarrousel] = useState([]);
  const lang = useLang();

  useEffect(() => {
    getCarrousels()
      .then((res) => {
        if (res.data) {
          setCarrousel(res.data.filter((c) => c.active === true));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <Carousel indicators={false}>
      {carrousel.map((item, index) => (
        <Carousel.Item key={index}>
          <div
            className="row slide1 mx-0 cooperhewitt"
            style={{
              backgroundImage: `url(${SERVER_URL}/${encodeImageURL(
                item.imageURL
              )})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: `${item.position}`,
              minHeight: "650px",
            }}
          >
            <div className="col-12 col-lg-3 px-0">
              <div className="bglightgrey2 h-100 d-flex align-items-center f20 p-5">
                <div>
                  <h2>{item?.title[lang]}</h2>
                  <h6>{item?.subtitle[lang]}</h6>
                  {item?.destiny[lang] && (
                    <button
                      onClick={() => {
                        window.location.href = item?.destiny[lang];
                      }}
                      className="my-3 btnbrown"
                    >
                      <FormattedMessage id="HOME.BTNCARROUSEL" />
                    </button>
                  )}
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-9"></div>
          </div>
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

export default DinamicCarrousel;
