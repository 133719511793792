import React, { useState, useEffect } from 'react'
import { injectIntl } from 'react-intl'
import test1 from '../assets/test1.jpg'
import test3 from '../assets/test3.jpg'
import CustomHelmet from '../components/seo/CustomHelmet'
import { useInView } from 'react-intersection-observer';
import clsx from 'clsx'
import { useLang } from '../translations/i18n'
import { RUTAS } from '../constants/routes.constants'

function SkinCare({ intl }) {
	const lang = useLang()
	const { ref, inView/*, entry*/ } = useInView({
    /* Optional options */
    threshold: 0,
		delay: 1500,
		triggerOnce: true
	});

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	return (<>
		<CustomHelmet
			title={intl.formatMessage({ id: 'SKINCARE.TITLESEO' }) || ''}
			description={intl.formatMessage({ id: 'SKINCARE.DESCRIPTIONSEO' }) || ''}
		/>
		<div className='container py-5'>
			<div className='row'>
				<div className='col-12 colorgrey'>
					<h1>Skin Care</h1>
					<h2><div dangerouslySetInnerHTML={ { __html: intl.formatMessage({ id: 'SKINCARE.SUBTITLE' }) } } /></h2>
				</div>
			</div>
			<div className='row'>
				<div className='col-12 colorgrey py-2'>
					<hr/>
					<h1>Body Care</h1>
				</div>
			</div>
			<div className='row'>
				<div className='col-12 colorgrey py-2'>
					<hr/>
					<h1>Face Care</h1>
				</div>
			</div>
		</div>
		<div className='container-fluid'>
			<div className='row'>
				<div className='col-12 bg-skincare1 p-5'>
					<h1 className='apercuregular text-white col-12 col-lg-6'><div dangerouslySetInnerHTML={ { __html: intl.formatMessage({ id: 'SKINCARE.TEXT1' }) } } /></h1>
				</div>
			</div>
		</div>
		<div className='container-fluid'>
			<div className='row'>
				<div className='col-12 bg-skincare2 p-5'>
					<h1 className='apercuregular text-white col-12 offset-lg-6 col-lg-6'><div dangerouslySetInnerHTML={ { __html: intl.formatMessage({ id: 'SKINCARE.TEXT2' }) } } /></h1>
				</div>
			</div>
		</div>
	</>
  	)
}

export default injectIntl(SkinCare)
