import React, { useState, useEffect, useContext } from "react";
import { injectIntl } from "react-intl";
import test1 from "../assets/test1.jpg";
import test3 from "../assets/test3.jpg";
import CustomHelmet from "../components/seo/CustomHelmet";
import { useInView } from "react-intersection-observer";
import clsx from "clsx";
import { useLang } from "../translations/i18n";
import videoheaderperfumebodegon from "../assets/videoheaderperfumebodegon.webm";
import perfumesbodegon from "../assets/perfumesbodegon.webm";
import { RUTAS } from "../constants/routes.constants";
import TranslatedLink from "../components/navigation/TranslatedLink";
import SubMenuContext from "../components/context/SubMenuContext";

function AboutUs({ intl }) {
  const lang = useLang();
  const { ref, inView /*, entry*/ } = useInView({
    /* Optional options */
    threshold: 0,
    delay: 1500,
    triggerOnce: true,
  });

  const { updateMenuPosition } = useContext(SubMenuContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <CustomHelmet
        title={intl.formatMessage({ id: "PERFUMES.TITLESEO" }) || ""}
        description={
          intl.formatMessage({ id: "PERFUMES.DESCRIPTIONSEO" }) || ""
        }
      />
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 px-0">
            <video loop autoPlay muted playsInline className="img-fluid">
              <source src={videoheaderperfumebodegon} type="video/webm" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </div>
      <div className="container px-0">
        <div className="row my-3">
          <div className="col-12 col-lg-8">
            <div className="bg-perfume h-100"></div>
          </div>
          <div className="col-12 col-lg-4 ">
            <div className="bglightgrey h-100 d-flex align-items-center p-5">
              <div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: intl.formatMessage({ id: "PERFUMES.TEXT1" }),
                  }}
                />
                <div className="d-flex flex-wrap mt-5">
                  <button className="btnoutline">
                    <TranslatedLink to={RUTAS.WOODLE}>Woodle</TranslatedLink>
                    <sup>&reg;</sup>
                  </button>
                  <button className="btnoutline">
                    <TranslatedLink to={RUTAS.WOORIGIN}>
                      Woorigin
                    </TranslatedLink>
                    <sup>&reg;</sup>
                  </button>
                  <button className="btnoutline">
                    <TranslatedLink to={RUTAS.WOON}>Woon</TranslatedLink>
                  </button>
                  <button className="btnoutline">
                    <TranslatedLink to={RUTAS.WOORK}>Woork</TranslatedLink>
                    <sup>&reg;</sup>
                  </button>
                  <button className="btnoutline">
                    <TranslatedLink to={RUTAS.PREFILL}>P-Refill</TranslatedLink>
                    <sup>&reg;</sup>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-lg-5 ">
            <video
              loop
              autoPlay
              muted
              playsInline
              className="img-fluid bg-perfume2"
            >
              <source src={perfumesbodegon} type="video/webm" />
              Your browser does not support the video tag.
            </video>
          </div>
          <div className="col-12 col-lg-7">
            <div className="h-100 sup-bg-perfume3">
              <div className="bg-perfume3 h-100"></div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 text-center py-5">
            <h2 className="title">
              <div
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage({ id: "PERFUMES.TEXTCOMMITED" }),
                }}
              />
            </h2>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="bg-perfumecomprometidos h-100"></div>
          </div>
        </div>
        <div className="row my-3">
          <div className="col-12 col-lg-6 ">
            <div className="bglightgrey h-100 d-flex align-items-center p-5">
              <div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: intl.formatMessage({ id: "PERFUMES.TEXT2" }),
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6">
            <div className="bg-perfumecomprometidos2 h-100"></div>
          </div>
        </div>
        <div className="row my-3">
          <div className="col-12 col-lg-6 ">
            <div className="mx-5 my-5">
              <div className="bg-perfumeconstanteinvestigacion mx-2 mx-lg-5"></div>
              <p className="text-center my-4 mx-2 mx-lg-5">
                <div
                  className="cooperhewitt"
                  dangerouslySetInnerHTML={{
                    __html: intl.formatMessage({ id: "PERFUMES.TEXT3" }),
                  }}
                />
              </p>
            </div>
          </div>
          <div className="col-12 col-lg-6 ">
            <div className="mx-5 my-5">
              <p className="text-center my-4 mx-2 mx-lg-5">
                <div
                  className="cooperhewitt"
                  dangerouslySetInnerHTML={{
                    __html: intl.formatMessage({ id: "PERFUMES.TEXT4" }),
                  }}
                />
              </p>
              <div className="bg-perfumenuestrosdesarrollos mx-2 mx-lg-5"></div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="bg-perfumebodegon h-100"></div>
          </div>
        </div>
        <div className="row my-3">
          <div className="col-12 col-lg-6 ">
            <div className="mx-5 my-5">
              <div className="bg-perfumecreamospiezas mx-2 mx-lg-5"></div>
              <p className="text-center my-4 mx-2 mx-lg-5">
                <div
                  className="cooperhewitt"
                  dangerouslySetInnerHTML={{
                    __html: intl.formatMessage({ id: "PERFUMES.TEXT5" }),
                  }}
                />
              </p>
            </div>
          </div>
          <div className="col-12 col-lg-6 ">
            <div className="mx-5 my-5">
              <p className="text-center my-4 mx-2 mx-lg-5">
                <div
                  className="cooperhewitt"
                  dangerouslySetInnerHTML={{
                    __html: intl.formatMessage({ id: "PERFUMES.TEXT6" }),
                  }}
                />
              </p>
              <div className="bg-perfumeentendernecesidades mx-2 mx-lg-5"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default injectIntl(AboutUs);
