import React, { useEffect } from "react";
import { injectIntl } from "react-intl";
import CustomHelmet from "../components/seo/CustomHelmet";
import { useLang } from "../translations/i18n";

function InformationSystem({ intl }) {
  const lang = useLang();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let txt = (
    <>
      <h1>Sistema interno de información</h1>
      <h5>I.- SISTEMA INTERNO DE INFORMACIÓN. </h5>
      <p className="p1">
        En cumplimiento de la Ley 2/2023, de 20 de febrero, reguladora de la
        protección de las personas que informen sobre infracciones normativas y
        de lucha contra la corrupción, en adelante, la “Ley 2/2023”,{" "}
        <strong>PUJOLASOS, S.L.</strong> hace constar que cuenta con un Sistema
        Interno de Información, siendo la empresa la responsable del tratamiento
        de los datos personales de conformidad con lo dispuesto por la
        legislación vigente en este ámbito.
      </p>
      <p className="p1">
        Con la finalidad de fortalecer la cultura de la información y de las
        infraestructuras de integridad de <strong>PUJOLASOS, S.L.</strong>., y
        de fomentar la cultura de la comunicación como mecanismo de prevención
        de actividades u omisiones que puedan ser constitutivas de infracciones
        laborales, penales o administrativas graves o muy graves así como del
        derecho de la UE, la empresa cuenta con una persona Responsable de
        Cumplimiento Penal (RCP), actualmente la persona responsable de Calidad
        de la empresa, quien ha sido así mismo nombrada persona Responsable del
        Sistema Interno de información (RSII). Asimismo, la empresa cuenta con
        una persona Responsable Alternativo en caso de que la información
        señalara como responsable de las menciones infracciones a la persona RCP
        y RSII, que es actualmente la Directora Financiera de la empresa.
      </p>
      <p className="p1">
        Las referidas informaciones pueden hacerse llegar por cualquiera de los
        siguientes medios al RCP/RSII: -Correo ordinario en la dirección:
        Polígono Industrial, Avenida Fontsanta, s/n, 08572 de la localidad de
        Sant Pere de Torelló (Barcelona). -a la atención de la persona RCP/RSII
        o RCP/RSII alternativa, en su caso- -Correo electrónico a la dirección:
        canaletic@pujolasos.com, cuando se dirige a la persona RCP/RSII y
        canaletic2@pujolasos.com, cuando se dirige a la persona RCP/RSII
        alternativa. -Mediante escrito entregado a la persona RCP/RSII o, en su
        caso, a la persona RCP/RSII, alternativa.
      </p>
      <p className="p1">
        A solicitud de la persona informante, también podrá presentarse la
        comunicación mediante una reunión presencial con la persona RCP/RSII (o,
        en su caso, con la persona RCP/RSII alternativa) en el plazo de siete
        días.
      </p>
      <p className="p1">
        Las informaciones verbales realizadas a través de reunión presencial con
        la persona RCP/RSII (o, en su caso, a la persona RCP/RSII alternativa)
        deberán documentarse de alguna de las maneras siguientes, previo
        consentimiento de la persona informante: <br />
        
        a) mediante una grabación de la
        conversación en un formato seguro, duradero y accesible, o (*) Se
        advertirá a la persona informante que la comunicación será grabada y se
        le informará del tratamiento de sus datos de acuerdo con lo establecido
        en el Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo, de
        27 de abril de 2016 (RGPD o Reglamento General de Protección de Datos)<br />

        b) a través de una transcripción completa y exacta de la conversación
        realizada por el personal responsable de tratarla. (*) Sin perjuicio de
        los derechos que le corresponden de acuerdo con la normativa sobre
        protección de datos, se ofrecerá a la persona informante la oportunidad
        de comprobar, rectificar y aceptar mediante su firma la transcripción de
        la conversación.
      </p>
      <p className="p1">
      El Sistema Interno de Información, cumple con los requisitos del artículo 5.2 de la Ley 2/2023:<br />
a). - Permite que las personas a quienes aplica la Ley2/2023 puedan comunicar
informaciones, por varios medios, sobre las infracciones previstas en su artículo 2.<br />
b). - Se gestiona de forma segura, garantizando que las comunicaciones puedan tratarse de
forma efectiva dentro de la empresa, así como la confidencialidad de la identidad de la
persona informante y de cualquier tercera persona mencionada en la comunicación y de las
actuaciones que se desarrollen en la gestión y tramitación de esta, así como la protección de
datos, impidiendo el acceso de personal no autorizado.<br />
c). - Cuenta con un Protocolo de Sistema Interno de Información, de uso de canal interno
de información y de actuación de la persona Responsable de Cumplimiento Penal y de
Sistema Interno de Información, que establece garantías para la protección de las personas
informantes:<br />
-Justificante de recepción en el plazo de siete días naturales siguientes a la recepción de
la información.<br />
-Plazo máximo de tres meses para dar respuesta a las actuaciones de la investigación, en
los términos del artículo 9 de la Ley 2/2023, cumplimentando y custodiando de forma
diligente un Libro-Registro de Informaciones.<br />
-Posibilidad de mantener la comunicación con la persona informante.<br />
-Establecimiento del derecho de la persona afectada a que se le informe de las acciones
u omisiones que se le atribuyen y a ser escuchada.<br />
-Garantía de confidencialidad cuando la comunicación sea remitida por canales de
denuncia que no sean los establecidos o a personal no responsable de su tratamiento, así
como la obligación de que la persona que la reciba, la remita inmediatamente a la persona
Responsable del Sistema.<br />
-Respecto a la presunción de inocencia y al honor de las personas afectadas.<br />
-Respecto a las disposiciones sobre Protección de Datos (Título VI Ley 2/2023).<br />
-Compromiso de remisión de la información al Ministerio Fiscal con carácter inmediato,
cuando los hechos pudieran ser indiciariamente constitutivos de delito. <br />
      </p>
      <h5>II.- TRATAMIENTO DE DATOS PERSONALES. </h5>
      <p className="p1"><strong>PUJOLASOS, S.L.</strong> tratará, como responsable del tratamiento, los datos personales incluidos
en las comunicaciones que se reciban y queden amparados por la Ley 2/2023, con la finalidad
de poder gestionarlas e iniciar, en su caso, el procedimiento de investigación correspondiente
y adoptar las medidas correctoras que, en su caso, procedan</p>
      <p className="p1">La base jurídica del tratamiento será el cumplimiento de las obligaciones impuestas por la
Ley 2/2023. Si la comunicación contiene datos de carácter especial, éstos sólo se tratarán
cuando resulte estrictamente necesario para la adopción de medidas correctoras y/o el inicio
del procedimiento de investigación correspondiente y/o la tramitación de los procedimientos
sancionadores o penales que, en su caso, procedan, de conformidad con la legislación vigente,
y en estos casos, la base jurídica será el interés público esencial. El tratamiento de los datos
personales será el indispensable, cuando sin ellos no se puedan cumplir los objetivos y las
obligaciones que estipula la Ley 2/2023. </p>
      <p className="p1">Los datos personales podrán ser tratados por el personal autorizado para ello únicamente
cuando resulte necesario para la investigación de infracciones en el ámbito del Derecho de
la Unión Europea, infracciones penales o administrativas graves o muy graves, así como
infracciones laborales en el ámbito de la seguridad y la salud en el trabajo, para la adopción
de medidas correctoras o bien para la tramitación de los procedimientos sancionadores o
penales que, en su caso, procedan. Asimismo, los datos personales podrán ser comunicados
a terceros en caso de obligación legal, y podrán ser comunicados a la autoridad judicial, al
Ministerio Fiscal o a la autoridad administrativa competentes en el marco de la mencionada
investigación.</p>
      <p className="p1">En caso de que fuese oportuno adoptar medidas correctoras, los datos se conservarán
durante todo el tiempo que dure la aplicación de estas medidas. Por otro lado, en el supuesto
que fuese necesario iniciar la tramitación de procedimientos sancionadores o penales, los
datos se conservarán durante todo el tiempo que dure el procedimiento sancionador o
penal.</p>
      <p className="p1">En todo caso, si la decisión sobre la procedencia de iniciar una investigación sobre los hechos
informados no se adoptase en un plazo de tres meses, los datos personales contenidos en
la comunicación se suprimirán, excepto aquellos datos que sea estrictamente necesario que
se conserven bloqueados para mantener evidencia del funcionamiento del SII de
conformidad con la Ley 2/2023. </p>
      <p className="p1">También serán suprimidos aquellos datos personales que revelen conductas que no están
incluidas en el ámbito de aplicación de la Ley 2/2023, así como aquellos datos personales no
considerados veraces, a excepción de que esta falta de veracidad pueda constituir un ilícito
penal, en cuyo caso se guardará la información por el tiempo necesario durante el cual se
tramite el procedimiento judicial.</p>
      <p className="p1">Finalmente, se hace constar que en cualquier momento la persona comunicante podrá
solicitar al responsable del tratamiento, el acceso a sus datos personales, su rectificación o
supresión, o la limitación de su tratamiento, o a oponerse, así como el derecho a la
portabilidad de los datos, enviando un escrito al correo postal Polígono Industrial, Avenida
Fontsanta, s/n, 08572 de la localidad de Sant Pere de Torelló (Barcelona) o al correo electrónico comercial@pujolasos.com. En caso de disconformidad con el tratamiento de sus
datos, podrá presentar una reclamación ante la Agencia Española de Protección de Datos,
organismo que ostenta la autoridad de control en la materia, ubicada en la C/ Jorge Juan, 6
(28001) Madrid (www.aepd.es) </p>

  <h5>III.- NO REPRESALIA.</h5>
    <p className="p1"><strong>PUJOLASOS, S.L.</strong> se compromete expresamente, a no llevar a cabo actos constitutivos de
represalia, incluidas amenazas o tentativas de represalia contra las personas que presenten
una comunicación conforme a lo previsto en la Ley 2/2023, y a aplicar medidas de protección
durante la tramitación de un expediente, respecto de las personas afectadas por una posible
comunicación. </p>

  <h5>IV.- EXENCIÓN Y ATENUACIÓN DE LA SANCIÓN</h5>
  <p className="p1">
  Cuando una persona que hubiera participado en la comisión de la infracción administrativa
objeto de la información sea la que informe de su existencia mediante la presentación de la
información y siempre que ésta hubiera sido presentada con anterioridad a que hubiera sido
notificada la incoación del procedimiento de investigación o sancionador, el órgano
competente para resolver el procedimiento, mediante resolución motivada, podrá eximirlo
del cumplimiento de la sanción administrativa que le correspondiera siempre que resulten
acreditados los extremos mencionados en el artículo 40 de la Ley 2/2023. 
  </p>

  <hr className="solid" />
(*) El Canal interno de información permite la presentación de comunicaciones anónimas. <br/>
(**) Si bien siempre que sea posible, el uso del canal interno será preferente, las comunicaciones podrán también
remitirse en su caso, a la Autoridad Independiente de Protección del Informante o a las autoridades u órganos
autonómicos correspondientes, al Ministerio Fiscal, a la Fiscalía Europea, o autoridad competente según sea
procedente.
  </>
  );

  if (lang === "ca")
  txt = (
    <>
      <h1>Sistema intern de informació</h1>
      <h5>I.- SISTEMA INTERN DE INFORMACIÓ. </h5>
      <p className="p1">
        En compliment de la Llei 2/2023, de 20 de febrer, reguladora de la protecció de les persones que informin sobre infraccions normatives i de lluita contra la corrupció, d'ara endavant, la "Llei 2/2023," <strong>PUJOLASOS, S.L.</strong> fa constar que compta amb un Sistema Intern de Informació, essent l'empresa responsable del tractament de les dades personals de conformitat amb el que disposa la legislació vigent en aquest àmbit.
      </p>
      <p className="p1">
        Amb la finalitat de reforçar la cultura de la informació i de les infraestructures d'integritat de <strong>PUJOLASOS, S.L.</strong>. i de fomentar la cultura de la comunicació com a mecanisme de prevenció d'activitats o omissions que puguin ser constitutives d'infraccions laborals, penals o administratives greus o molt greus així com del dret de la UE, l'empresa compta amb una persona Responsable de Cumpliment Penal (RCP), actualment la persona responsable de Qualitat de l'empresa, qui ha estat així mateix nomenada persona Responsable del Sistema Intern de informació (RSII). A més, l'empresa compta amb una persona Responsable Alternativa en cas que la informació senyali com a responsable de les esmentades infraccions la persona RCP i RSII, que actualment és la Directora Financera de l'empresa.
      </p>
      <p className="p1">
        Les referides informacions es poden fer arribar per qualsevol dels següents mitjans al RCP/RSII: -Correu ordinari a l'adreça: Polígon Industrial, Avinguda Fontsanta, s/n, 08572 de la localitat de Sant Pere de Torelló (Barcelona). -a l'atenció de la persona RCP/RSII o RCP/RSII alternativa, si escau- -Correu electrònic a l'adreça: canaletic@pujolasos.com, quan es dirigeix a la persona RCP/RSII i canaletic2@pujolasos.com, quan es dirigeix a la persona RCP/RSII alternativa. -Mitjançant escrit entregat a la persona RCP/RSII o, si escau, a la persona RCP/RSII, alternativa.
      </p>
      <p className="p1">
        A sol·licitud de la persona informadora, també es podrà presentar la comunicació mitjançant una reunió presencial amb la persona RCP/RSII (o, si escau, amb la persona RCP/RSII alternativa) en el termini de set dies.
      </p>
      <p className="p1">
        Les informacions verbals realitzades a través de reunió presencial amb la persona RCP/RSII (o, si escau, amb la persona RCP/RSII alternativa) hauran de documentar-se d'alguna de les maneres següents, prèvia consentiment de la persona informadora: <br />
  
        a) mitjançant una gravació de la conversa en un format segur, durador i accessible, o (*) S'advertirà a la persona informadora que la comunicació serà enregistrada i se li informarà del tractament de les seves dades d'acord amb el que estableix el Reglament (UE) 2016/679 del Parlament Europeu i del Consell, de 27 d'abril de 2016 (RGPD o Reglament General de Protecció de Dades) <br />
  
        b) a través d'una transcripció completa i exacta de la conversa realitzada pel personal responsable de tractar-la. (*) Sense perjudici dels drets que li corresponen d'acord amb la normativa sobre protecció de dades, se li oferirà a la persona informadora l'oportunitat de comprovar, rectificar i acceptar mitjançant la seva signatura la transcripció de la conversa.
      </p>
      <p className="p1">
        El Sistema Intern de Informació compleix amb els requisits de l'article 5.2 de la Llei 2/2023: <br />
        a). - Permet que les persones a qui s'aplica la Llei 2/2023 puguin comunicar informacions, per diversos mitjans, sobre les infraccions previstes en el seu article 2. <br />
        b). - Es gestiona de manera segura, garantint que les comunicacions puguin tractar-se de manera efectiva dins de l'empresa, així com la confidencialitat de la identitat de la persona informadora i de qualsevol tercera persona esmentada a la comunicació i de les actuacions que es desenvolupin en la gestió i tramitació d'aquesta, així com la protecció de dades, impedint l'accés de personal no autoritzat. <br />
        c). - Compta amb un Protocol de Sistema Intern de Informació, d'ús de canal intern d'informació i d'actuació de la persona Responsable de Cumpliment Penal i de Sistema Intern de Informació, que estableix garanties per a la protecció de les persones informadores: <br />
        -Justificant de recepció en el termini de set dies naturals següents a la recepció de la informació. <br />
        -Termini màxim de tres mesos per donar resposta a les actuacions de la investigació, en els termes de l'article 9 de la Llei 2/2023, complimentant i custodiant de manera diligent un Llibre-Registre d'Informacions. <br />
        -Possibilitat de mantenir la comunicació amb la persona informadora. <br />
        -Establiment del dret de la persona afectada a ser informada de les accions o omissions que se li atribueixin i a ser escoltada. <br />
        -Garantia de confidencialitat quan la comunicació sigui remesa per canals de denúncia que no siguin els establerts o a personal no responsable del seu tractament, així com l'obligació que la persona que la rebi la remeti immediatament a la persona Responsable del Sistema. <br />
        -Respecte a la presumpció d'innocència i a l'honor de les persones afectades. <br />
        -Respecte a les disposicions sobre Protecció de Dades (Títol VI Llei 2/2023). <br />
        -Compromís de remissió de la informació al Ministeri Fiscal amb caràcter immediat, quan els fets poguessin ser indicis constitutius de delicte. <br />
      </p>
      <h5>II.- TRACTAMENT DE DADES PERSONALS. </h5>
      <p className="p1"><strong>PUJOLASOS, S.L.</strong> tractarà, com a responsable del tractament, les dades personals incloses en les comunicacions que es rebin i quedin amparades per la Llei 2/2023, amb la finalitat de poder gestionar-les i iniciar, si escau, el procediment d'investigació corresponent i adoptar les mesures correctores que, si escau, procedeixin.</p>
      <p className="p1">La base jurídica del tractament serà el compliment de les obligacions imposades per la Llei 2/2023. Si la comunicació conté dades de caràcter especial, aquestes només es tractaran quan sigui estrictament necessari per a l'adopció de mesures correctores i/o l'inici del procediment d'investigació corresponent i/o la tramitació de procediments sancionadors o penals que, si escau, procedeixin, de conformitat amb la legislació vigent, i en aquests casos, la base jurídica serà l'interès públic essencial. El tractament de les dades personals serà el indispensable quan siguin necessàries per a complir els objectius i les obligacions que estipula la Llei 2/2023.</p>
      <p className="p1">Les dades personals podran ser tractades pel personal autoritzat per a això únicament quan sigui necessari per a la investigació d'infraccions en l'àmbit del Dret de la Unió Europea, infraccions penals o administratives greus o molt greus, així com infraccions laborals en l'àmbit de la seguretat i la salut en el treball, per a l'adopció de mesures correctores o bé per a la tramitació dels procediments sancionadors o penals que, si escau, procedeixin. A més, les dades personals podran ser comunicades a tercers en cas d'obligació legal, i podran ser comunicades a l'autoritat judicial, al Ministeri Fiscal o a l'autoritat administrativa competent dins del marc de l'esmentada investigació.</p>
      <p className="p1">En cas que fos oportú adoptar mesures correctores, les dades es conservaran durant tot el temps que duri l'aplicació d'aquestes mesures. D'altra banda, en el cas que fos necessari iniciar la tramitació de procediments sancionadors o penals, les dades es conservaran durant tot el temps que duri el procediment sancionador o penal.</p>
      <p className="p1">En tot cas, si la decisió sobre la procedència d'iniciar una investigació sobre els fets informats no es prengués en un termini de tres mesos, les dades personals contingudes a la comunicació s'esborraran, excepte aquelles dades que sigui estrictament necessari que es conservin bloquejades per mantenir evidència del funcionament del SII de conformitat amb la Llei 2/2023.</p>
      <p className="p1">També seran esborrades aquelles dades personals que revelin conductes que no estiguin incloses en l'àmbit d'aplicació de la Llei 2/2023, així com aquelles dades personals no considerades veraces, llevat que aquesta manca de veracitat pugui constituir un il·lícit penal, en què cas la informació es guardarà pel temps necessari durant el qual es tramiti el procediment judicial.</p>
      <p className="p1">Finalment, es fa constar que en qualsevol moment la persona comunicant podrà sol·licitar al responsable del tractament, l'accés a les seves dades personals, la seva rectificació o supressió, o la limitació del seu tractament, o oposar-se, així com el dret a la portabilitat de les dades, enviant un escrit a l'adreça postal Polígon Industrial, Avinguda Fontsanta, s/n, 08572 de la localitat de Sant Pere de Torelló (Barcelona) o a l'adreça electrònica comercial@pujolasos.com. En cas de disconformitat amb el tractament de les seves dades, podrà presentar una reclamació davant l'Agència Espanyola de Protecció de Dades, organisme que ostenta l'autoritat de control en la matèria, ubicada a la C/ Jorge Juan, 6 (28001) Madrid (www.aepd.es).</p>
  
      <h5>III.- NO REPRESÀLIA.</h5>
<p className="p1"><strong>PUJOLASOS, S.L.</strong> es compromet expressament a no dur a terme actes constitutius de represàlia, incloent amenaces o temptatives de represàlia contra les persones que presentin una comunicació conforme al previst a la Llei 2/2023, i a aplicar mesures de protecció durant la tramitació d'un expedient, respecte de les persones afectades per una possible comunicació.</p>

<h5>IV.- EXEMCIÓ I ATENUACIÓ DE LA SANCIONI</h5>
<p className="p1">
Quan una persona que hagués participat en la comissió de la infracció administrativa objecte de la informació sigui qui informi de la seva existència mitjançant la presentació de la informació i sempre que aquesta hagi estat presentada amb anterioritat a la notificació de l'inici del procediment d'investigació o sancionador, l'òrgan competent per a resoldre el procediment, mitjançant resolució motivada, podrà exemptar-lo del compliment de la sanció administrativa que li correspongui sempre que es provin els extrems esmentats a l'article 40 de la Llei 2/2023.
</p>

<hr className="solid" />
(*) El Canal intern d'informació permet la presentació de comunicacions anònimes. <br/>
(**) Tot i que sempre que sigui possible, l'ús del canal intern serà preferent, les comunicacions també es podran remetre, si escau, a l'Autoritat Independent de Protecció del Informant o a les autoritats o òrgans autonòmics corresponents, al Ministeri Fiscal, a la Fiscalia Europea o a l'autoritat competent segons sigui procedent.


   </> 
  )
  if (lang === "en")
  txt = (
    <>
      <h1>Internal Information System</h1>
      <h5>I.- INTERNAL INFORMATION SYSTEM. </h5>
      <p className="p1">
        In compliance with Law 2/2023, of February 20, regulating the protection of individuals reporting regulatory violations and corruption, hereinafter, "Law 2/2023," <strong>PUJOLASOS, S.L.</strong> hereby states that it has an Internal Information System, with the company being responsible for the processing of personal data in accordance with the applicable legislation in this area.
      </p>
      <p className="p1">
        In order to strengthen the culture of information and integrity infrastructure at <strong>PUJOLASOS, S.L.</strong> and to promote a culture of communication as a mechanism for preventing serious or very serious labor, criminal, or administrative offenses, as well as EU law violations, the company has a Compliance Officer (CO), currently the Quality Manager of the company, who has also been appointed as the Responsible for the Internal Information System (RIIS). Additionally, the company has an Alternate Responsible Person in case the information attributes these violations to the CO and RIIS, who is currently the Chief Financial Officer of the company.
      </p>
      <p className="p1">
        The aforementioned information can be submitted through any of the following means to the CO/RIIS: -Regular mail to the address: Industrial Estate, Fontsanta Avenue, s/n, 08572, Sant Pere de Torelló (Barcelona). -Attention of the CO/RIIS or Alternate CO/RIIS, as applicable- -Email to the address: canaletic@pujolasos.com, when directed to the CO/RIIS, and canaletic2@pujolasos.com, when directed to the Alternate CO/RIIS. -By written communication delivered to the CO/RIIS or, as the case may be, to the Alternate CO/RIIS.
      </p>
      <p className="p1">
        At the request of the reporting person, communication can also be made through an in-person meeting with the CO/RIIS (or, as the case may be, with the Alternate CO/RIIS) within seven days.
      </p>
      <p className="p1">
        Verbal information provided through an in-person meeting with the CO/RIIS (or, as the case may be, with the Alternate CO/RIIS) must be documented in one of the following ways, with the prior consent of the reporting person: <br />
  
        a) by recording the conversation in a secure, durable, and accessible format, or (*) The reporting person will be informed that the communication will be recorded, and they will be informed of the processing of their data in accordance with Regulation (EU) 2016/679 of the European Parliament and of the Council of April 27, 2016 (GDPR - General Data Protection Regulation) <br />
  
        b) through a complete and accurate transcription of the conversation made by the personnel responsible for processing it. (*) Without prejudice to the rights granted to them under data protection regulations, the reporting person will be given the opportunity to verify, rectify, and accept the transcription of the conversation by signing it.
      </p>
      <p className="p1">
        The Internal Information System complies with the requirements of Article 5.2 of Law 2/2023: <br />
        a). - Allows individuals covered by Law 2/2023 to report information through various means about the offenses outlined in Article 2. <br />
        b). - Is managed securely, ensuring that communications can be effectively processed within the company, as well as the confidentiality of the reporting person's identity and any third party mentioned in the communication and the actions taken in the management and processing of it, as well as data protection, preventing unauthorized access by personnel. <br />
        c). - Has a Protocol for the Internal Information System, internal information channel usage, and the actions of the Compliance Officer and Responsible for the Internal Information System, which provides guarantees for the protection of reporting persons: <br />
        -Receipt acknowledgment within seven calendar days following receipt of the information. <br />
        -A maximum period of three months to respond to investigative actions, in accordance with Article 9 of Law 2/2023, by completing and diligently maintaining an Information Log Book. <br />
        -The possibility of maintaining communication with the reporting person. <br />
        -Establishment of the right of the affected person to be informed of the actions or omissions attributed to them and to be heard. <br />
        -Guarantee of confidentiality when the communication is sent through reporting channels other than those established or to personnel not responsible for their processing, with the obligation for the recipient to immediately forward it to the Responsible Person of the System. <br />
        -Respect for the presumption of innocence and the honor of the affected persons. <br />
        -Regarding provisions on Data Protection (Title VI of Law 2/2023). <br />
        -Commitment to immediately send the information to the Public Prosecutor when the facts may be indicative of a crime. <br />
      </p>
      <h5>II.- PROCESSING OF PERSONAL DATA. </h5>
      <p className="p1"><strong>PUJOLASOS, S.L.</strong> will process, as the data controller, the personal data included in the communications received and covered by Law 2/2023, for the purpose of managing them and initiating, if necessary, the corresponding investigation procedure and taking corrective measures as required.</p>
      <p className="p1">The legal basis for the processing will be compliance with the obligations imposed by Law 2/2023. If the communication contains special category data, such data will only be processed when strictly necessary for the adoption of corrective measures and/or the initiation of the corresponding investigation procedure and/or the processing of sanctions or criminal proceedings, if applicable, in accordance with current legislation, and in these cases, the legal basis will be essential public interest. The processing of personal data will be indispensable when they are necessary to fulfill the objectives and obligations stipulated by Law 2/2023.</p>
      <p className="p1">Personal data may be processed by authorized personnel only when necessary for the investigation of offenses within the scope of EU law, serious or very serious criminal or administrative offenses, as well as labor offenses in the field of safety and health at work, for the adoption of corrective measures or for the processing of sanctions or criminal proceedings, if applicable. Likewise, personal data may be disclosed to third parties in cases of legal obligation, and may be communicated to the judicial authority, the Public Prosecutor's Office, or the competent administrative authority in the context of the aforementioned investigation.</p>
      <p className="p1">In the event that it is appropriate to take corrective measures, the data will be retained for the entire duration of the application of these measures. Furthermore, in the event that it is necessary to initiate proceedings for sanctions or criminal proceedings, the data will be retained for the entire duration of the sanctioning or criminal procedure.</p>
      <p className="p1">In any case, if a decision regarding the appropriateness of initiating an investigation into the reported facts is not made within three months, the personal data contained in the communication will be deleted, except for those data that must be strictly retained as evidence of the functioning of the Internal Information System in accordance with Law 2/2023.</p>
      <p className="p1">Also, personal data revealing behaviors that are not within the scope of Law 2/2023 or personal data that are not considered truthful will be deleted, except in cases where this lack of truthfulness may constitute a criminal offense, in which case the information will be kept for the necessary time during which the judicial procedure is conducted.</p>
      <p className="p1">Finally, it is noted that at any time, the reporting person may request access to their personal data, rectification or deletion, or the restriction of their processing, or object to it, as well as the right to data portability, by sending a written request to the postal address Industrial Estate, Fontsanta Avenue, s/n, 08572, Sant Pere de Torelló (Barcelona) or to the email address commercial@pujolasos.com. In case of dissatisfaction with the processing of their data, a complaint can be filed with the Spanish Data Protection Agency, the authority responsible for data protection, located at C/ Jorge Juan, 6 (28001) Madrid (www.aepd.es).</p>
  
      <h5>III.- NO RETALIATION.</h5>
      <p className="p1"><strong>PUJOLASOS, S.L.</strong> expressly commits not to carry out acts constituting retaliation, including threats or attempts at retaliation against individuals who submit a communication in accordance with Law 2/2023, and to apply protection measures during the processing of a file with respect to individuals affected by a possible communication.</p>
  
      <h5>IV.- EXEMPTION AND MITIGATION OF SANCTION</h5>
      <p className="p1">When a person who has participated in the commission of the administrative offense subject to the information is the one who reports its existence by submitting the information, and provided that this information was submitted before the initiation of the investigation or sanctioning procedure was notified, the competent body to resolve the procedure, by reasoned decision, may exempt them from complying with the administrative sanction that would correspond to them, provided that the conditions mentioned in Article 40 of Law 2/2023 are proven.</p>
  
      <hr className="solid" />
      (*) The internal information channel allows for the submission of anonymous communications. <br/>
      (**) While the use of the internal channel will be preferred whenever possible, communications may also be sent, as appropriate, to the Independent Authority for Informant Protection or the corresponding regional authorities, the Public Prosecutor's Office, the European Public Prosecutor's Office, or the competent authority as appropriate.
    </>
  );

  if (lang === "fr")
  txt = (
    <>
    <h1>Système interne d'information</h1>
    <h5>I.- SYSTÈME INTERNE D'INFORMATION. </h5>
    <p className="p1">
      Conformément à la Loi 2/2023 du 20 février, régissant la protection des personnes qui signalent des violations de la loi et la lutte contre la corruption, ci-après dénommée la "Loi 2/2023", <strong>PUJOLASOS, S.L.</strong> tient à préciser qu'elle dispose d'un Système Interne d'Information, l'entreprise étant responsable du traitement des données personnelles conformément à la législation en vigueur dans ce domaine.
    </p>
    <p className="p1">
      Dans le but de renforcer la culture de l'information et les infrastructures d'intégrité de <strong>PUJOLASOS, S.L.</strong> et de promouvoir la culture de la communication en tant que mécanisme de prévention des activités ou omissions pouvant constituer des infractions graves ou très graves au travail, pénales ou administratives, ainsi que du droit de l'UE, l'entreprise dispose d'une personne responsable de la conformité pénale (RCP), actuellement responsable qualité de l'entreprise, qui a également été nommée responsable du système interne d'information (RSII). De plus, l'entreprise dispose d'une personne responsable alternative au cas où les informations attribueraient à la personne RCP et RSII la responsabilité desdites infractions, qui est actuellement la directrice financière de l'entreprise.
    </p>
    <p className="p1">
      Les informations mentionnées peuvent être transmises par l'un des moyens suivants au RCP/RSII : - Courrier ordinaire à l'adresse : Polígono Industrial, Avenida Fontsanta, s/n, 08572, Sant Pere de Torelló (Barcelone). - à l'attention de la personne RCP/RSII ou RCP/RSII alternative, le cas échéant - - Courrier électronique à l'adresse : canaletic@pujolasos.com, lorsqu'il est destiné à la personne RCP/RSII et canaletic2@pujolasos.com, lorsqu'il est destiné à la personne RCP/RSII alternative. - Par écrit remis à la personne RCP/RSII ou, le cas échéant, à la personne RCP/RSII alternative.
    </p>
    <p className="p1">
      À la demande de la personne informant, la communication peut également être effectuée lors d'une réunion en personne avec la personne RCP/RSII (ou, le cas échéant, avec la personne RCP/RSII alternative) dans un délai de sept jours.
    </p>
    <p className="p1">
      Les informations verbales recueillies lors d'une réunion en personne avec la personne RCP/RSII (ou, le cas échéant, avec la personne RCP/RSII alternative) doivent être documentées de l'une des manières suivantes, avec le consentement préalable de la personne informant : <br />

      a) par l'enregistrement de la conversation dans un format sécurisé, durable et accessible, ou (*) La personne informant sera informée que la communication sera enregistrée et sera informée du traitement de ses données conformément au Règlement (UE) 2016/679 du Parlement européen et du Conseil, du 27 avril 2016 (RGPD ou Règlement général sur la protection des données) <br />

      b) par une transcription complète et exacte de la conversation effectuée par le personnel chargé de la traiter. (*) Sans préjudice des droits qui lui sont reconnus en vertu de la réglementation sur la protection des données, la personne informant aura la possibilité de vérifier, de rectifier et d'accepter par sa signature la transcription de la conversation.
    </p>
    <p className="p1">
      Le Système Interne d'Information répond aux exigences de l'article 5.2 de la Loi 2/2023 : <br />
      a). - Permet aux personnes auxquelles s'applique la Loi 2/2023 de communiquer des informations, par divers moyens, sur les infractions prévues à l'article 2 de ladite loi. <br />
      b). - Il est géré de manière sécurisée, garantissant que les communications puissent être traitées de manière efficace au sein de l'entreprise, ainsi que la confidentialité de l'identité de la personne informant et de toute tierce personne mentionnée dans la communication, ainsi que la protection des données, en empêchant l'accès du personnel non autorisé. <br />
      c). - Il dispose d'un Protocole de Système Interne d'Information, d'utilisation du canal interne d'information et de la manière dont agit la personne responsable de la conformité pénale et du système interne d'information, établissant des garanties pour la protection des personnes informant : <br />
      - Justificatif de réception dans un délai de sept jours calendaires suivant la réception de l'information. <br />
      - Délai maximal de trois mois pour répondre aux actions de l'enquête, conformément à l'article 9 de la Loi 2/2023, en remplissant et en conservant de manière diligente un Livre-Registre des Informations. <br />
      - Possibilité de maintenir la communication avec la personne informant. <br />
      - Établissement du droit de la personne concernée à être informée des actions ou omissions qui lui sont attribuées et à être entendue. <br />
      - Garantie de confidentialité lorsque la communication est transmise par des canaux de dénonciation autres que ceux établis ou à du personnel non responsable de son traitement, ainsi que l'obligation pour la personne qui la reçoit de la transmettre immédiatement à la personne responsable du système. <br />
      - En ce qui concerne la présomption d'innocence et l'honneur des personnes concernées. <br />
      - En ce qui concerne les dispositions sur la protection des données (Titre VI de la Loi 2/2023). <br />
      - Engagement de transmettre l'information au ministère public immédiatement lorsque les faits pourraient constituer présumément une infraction pénale. <br />
    </p>
    <h5>II.- TRAITEMENT DES DONNÉES PERSONNELLES. </h5>
    <p className="p1"><strong>PUJOLASOS, S.L.</strong> traitera, en tant que responsable du traitement, les données personnelles incluses dans les communications reçues et couvertes par la Loi 2/2023, dans le but de pouvoir les gérer et, le cas échéant, d'initier la procédure d'enquête correspondante et de prendre les mesures correctives qui s'imposent.</p>
    <p className="p1">La base juridique du traitement sera le respect des obligations imposées par la Loi 2/2023. Si la communication contient des données de caractère spécial, celles-ci ne seront traitées que si elles sont strictement nécessaires à l'adoption de mesures correctives et/ou à l'ouverture de la procédure d'enquête correspondante et/ou à la gestion des procédures administratives ou pénales qui s'imposent, conformément à la législation en vigueur, et dans ces cas, la base juridique sera l'intérêt public essentiel. Le traitement des données personnelles sera strictement nécessaire lorsque, sans elles, il serait impossible d'atteindre les objectifs et les obligations prévus par la Loi 2/2023.</p>
    <p className="p1">Les données personnelles pourront être traitées par le personnel autorisé à cette fin uniquement lorsque cela sera nécessaire pour enquêter sur des infractions dans le domaine du droit de l'Union européenne, des infractions pénales ou administratives graves ou très graves, ainsi que des infractions au travail dans le domaine de la sécurité et de la santé au travail, pour l'adoption de mesures correctives ou pour la gestion des procédures administratives ou pénales qui s'imposent, le cas échéant. De plus, les données personnelles pourront être communiquées à des tiers en cas d'obligation légale, et pourront être communiquées à l'autorité judiciaire, au ministère public ou à l'autorité administrative compétente dans le cadre de l'enquête susmentionnée.</p>
    <p className="p1">Dans le cas où il serait opportun d'adopter des mesures correctives, les données seront conservées pendant toute la durée de l'application de ces mesures. Par ailleurs, si la nécessité de lancer des procédures administratives ou pénales devait se présenter, les données seraient conservées pendant toute la durée de la procédure administrative ou pénale.</p>
    <p className="p1">En tout état de cause, si la décision de lancer une enquête sur les faits signalés n'était pas prise dans un délai de trois mois, les données personnelles contenues dans la communication seraient supprimées, à l'exception de celles qui devraient être conservées bloquées pour conserver la preuve du fonctionnement du SII conformément à la Loi 2/2023.</p>
    <p className="p1">Seront également supprimées les données personnelles révélant des comportements qui ne sont pas inclus dans le champ d'application de la Loi 2/2023, ainsi que les données personnelles non considérées comme véridiques, sauf si ce manque de véracité pouvait constituer une infraction pénale, auquel cas les informations seraient conservées pendant la durée nécessaire à la procédure judiciaire.</p>
    <p className="p1">Enfin, il est à noter que la personne communicante peut à tout moment demander au responsable du traitement l'accès à ses données personnelles, leur rectification ou leur suppression, ou la limitation de leur traitement, ou s'opposer, ainsi que le droit à la portabilité des données, en envoyant un courrier à l'adresse postale Polígono Industrial, Avenida Fontsanta, s/n, 08572, Sant Pere de Torelló (Barcelone) ou à l'adresse électronique commercial@pujolasos.com. En cas de désaccord avec le traitement de ses données, elle peut déposer une réclamation auprès de l'Agence espagnole de protection des données, l'organisme chargé du contrôle en la matière, située au C/ Jorge Juan, 6 (28001) Madrid (www.aepd.es).</p>

    <h5>III.- ABSENCE DE REPRÉSAILLES.</h5>
<p className="p1"><strong>PUJOLASOS, S.L.</strong> s'engage expressément à ne pas entreprendre d'actes constitutifs de représailles, y compris des menaces ou des tentatives de représailles à l'encontre des personnes qui présentent une communication conforme à la Loi 2/2023, et à prendre des mesures de protection pendant le traitement d'un dossier, en ce qui concerne les personnes affectées par une éventuelle communication.</p>

<h5>IV.- EXONÉRATION ET ATTÉNUATION DE LA SANCTION</h5>
<p className="p1">
Lorsqu'une personne qui aurait participé à la commission de l'infraction administrative objet de l'information est celle qui informe de son existence par la présentation de l'information et à condition que celle-ci ait été présentée préalablement à la notification de l'ouverture de la procédure d'enquête ou de sanction, l'organe compétent pour statuer sur la procédure, par une décision motivée, pourra l'exonérer de l'exécution de la sanction administrative qui lui serait applicable, pour autant que les éléments mentionnés à l'article 40 de la Loi 2/2023 soient établis.
</p>

<hr className="solid" />
(*) Le Canal interne d'information permet la présentation de communications anonymes. <br/>
(**) Bien que l'utilisation du canal interne soit préférable dans la mesure du possible, les communications pourront également être transmises, le cas échéant, à l'Autorité Indépendante de Protection du Dénonciateur ou aux autorités ou organes autonomes compétents, au Ministère Public, à la Fiscalité Européenne, ou à l'autorité compétente, selon le cas.


    </>
  )
  
  return (
    <>
      <CustomHelmet
        title={intl.formatMessage({ id: "POLITICAPRIVACIDAD.TITLESEO" }) || ""}
        description={
          intl.formatMessage({ id: "POLITICAPRIVACIDAD.DESCRIPTIONSEO" }) || ""
        }
      />
      <div className="container-fluid px-3 px-lg-5 pb-5 secondary-pages">
        <div className="row">
          <div className="col-12">{txt}</div>
        </div>
      </div>
    </>
  );
}

export default injectIntl(InformationSystem);
