import React, { useState, useEffect } from "react";
import arrowup from "../assets/arrowup.svg";

function ScrollToTop() {
  const [showTopBtn, setShowTopBtn] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 400) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  //const doScroll = () => window.scrollTo(0, 0)

  return (
    <div className="top-to-btm">
      {" "}
      {showTopBtn && (
        <div onClick={goToTop}>
          <button className="my-3 icon-position cooperhewitt">
            GO UP <img src={arrowup} />
          </button>
        </div>
      )}{" "}
    </div>
  );
}

export default ScrollToTop;
