import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { I18nProvider } from "./translations/I18nProvider";
import { CustomI18nProvider } from "./translations/i18n";
import SubMenuProvider from "./components/context/SubMenuProvider";
import Rutas from "./Rutas";
import TagManager from "react-gtm-module";
import ReactGA from "react-ga4";
import { createBrowserHistory } from "history";

const tagManagerArgs = {
  gtmId: "GTM-MDWVC9BW",
  dataLayerName: "PageDataLayer",
};

const tagManagerArgs2 = {
  gtmId: "AW-11218049583",
};

TagManager.initialize(tagManagerArgs);
TagManager.initialize(tagManagerArgs2);
ReactGA.initialize("G-8S796VY838");
const history = createBrowserHistory();
history.listen((location) => {
  ReactGA.set({ page: location.pathname });
  ReactGA.send(location.pathname);
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <CustomI18nProvider>
      <I18nProvider>
        <SubMenuProvider>
          <Rutas />
        </SubMenuProvider>
      </I18nProvider>
    </CustomI18nProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
