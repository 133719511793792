import axios from 'axios'
import { API } from '../../utils/index'
const instance = axios.create()


export const getPress = async () => {
	const _endpoint = 'press'
	const URL = `${API}/${_endpoint}`
	return instance.get(URL)
}


export const getPres = async (id) => {
	const _endpoint = `press/${id}`
	const URL = `${API}/${_endpoint}`

	return instance.get(URL)
}