import React, { useState, useEffect } from "react";
import { injectIntl } from "react-intl";
import test1 from "../assets/test1.jpg";
import test3 from "../assets/test3.jpg";
import CustomHelmet from "../components/seo/CustomHelmet";
import { useInView } from "react-intersection-observer";
import clsx from "clsx";
import { useLang } from "../translations/i18n";
import icoinnovacion from "../assets/ico-valores-innovacion.png";
import icosostenibilidad from "../assets/ico-valores-sostenibilidad.png";
import icoresponsabilidad from "../assets/ico-valores-responsabilidad.png";
import icocalidad from "../assets/ico-valores-calidad.png";
import icocolaboracion from "../assets/ico-valores-colaboracion.png";
import { RUTAS } from "../constants/routes.constants";

function AboutUs({ intl }) {
  const [dataThehubs, setDataThehubs] = useState([]);

  const lang = useLang();
  const { ref, inView /*, entry*/ } = useInView({
    /* Optional options */
    threshold: 0,
    delay: 1500,
    triggerOnce: true,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    // 	const asyncFetchThehubs = async () => {
    // 		const dataThehubs = await handleFetchThehubs({ })
    // 		setDataThehubs(dataThehubs)
    // 	}
    // 	asyncFetchThehubs()
    // 	// eslint-disable-next-line
  }, []);

  return (
    <>
      <CustomHelmet
        title={intl.formatMessage({ id: "ABOUTUS.TITLESEO" }) || ""}
        description={intl.formatMessage({ id: "ABOUTUS.DESCRIPTIONSEO" }) || ""}
      />
      <div className="container mt-3 mb-0 my-lg-3">
        <div className="row">
          <div className="col-12 col-md-5 bglightgrey3 p-5 cooperhewitt d-flex align-items-center ">
            <div>
              <div
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage({ id: "ABOUTUS.TEXT1" }),
                }}
              />
            </div>
          </div>
          <div className="col-12 col-md-7 px-0 px-lg-3 py-3 py-lg-0">
            <div className="bg-abouthistoria h-100"></div>
          </div>
        </div>
      </div>
      <div className="container-fluid my-0 my-lg-3">
        <div className="row">
          <div className="col-12 bg-abouthistoria2"></div>
        </div>
      </div>
      <div className="container mt-3 mb-0 my-lg-3">
        <div className="row">
          <div className="col-12 col-md-6 bglightgrey p-5 cooperhewitt d-flex align-items-center ">
            <div
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage({ id: "ABOUTUS.TEXT2" }),
              }}
            />
          </div>
          <div className="col-12 col-md-6 px-0 px-lg-3 py-3 py-lg-0">
            <div className="bg-abouthistoria3 h-100"></div>
          </div>
        </div>
      </div>
      <div className="container my-0 my-lg-3">
        <div className="row">
          <div className="col-12 col-md-6 my-3 my-lg-0 bg-mision order-2 order-lg-1"></div>
          <div className="col-12 col-md-6 px-0 px-lg-3 py-0 py-lg-0 order-1 order-lg-2">
            <div className="bglightgrey3  p-5 h-100 cooperhewitt d-flex align-items-center ">
              <div
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage({ id: "ABOUTUS.TEXT3" }),
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="container my-0 my-lg-3">
        <div className="row">
          <div className="col-12 col-md-5 bglightgrey2 p-5 cooperhewitt d-flex align-items-center ">
            <div
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage({ id: "ABOUTUS.TEXT4" }),
              }}
            />
          </div>
          <div className="col-12 col-md-7 px-0 px-lg-3 py-3 py-lg-0">
            <div className="bg-vision h-100"></div>
          </div>
        </div>
      </div>
      <div className="container colorgrey my-3">
        <div className="row">
          <div className="col-12 cooperhewitt mt-4">
            <div
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage({ id: "ABOUTUS.TEXT5" }),
              }}
            />
          </div>
        </div>
        <div className="row my-5">
          <div className="col-12 col-md-6 my-3 cooperhewitt">
            <img src={icoinnovacion} className="ico-valores" />
            <div
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage({ id: "ABOUTUS.TEXTINNOVATION" }),
              }}
            />
          </div>
          <div className="col-12 col-md-6 my-3 cooperhewitt">
            <img src={icosostenibilidad} className="ico-valores" />
            <div
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage({
                  id: "ABOUTUS.TEXTSUSTAINABILITY",
                }),
              }}
            />
          </div>
          <div className="col-12 col-md-6 my-3">
            <img src={icoresponsabilidad} className="ico-valores" />
            <div
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage({
                  id: "ABOUTUS.TEXTRESPONSABILITY",
                }),
              }}
            />
          </div>
          <div className="col-12 col-md-6 my-3">
            <img src={icocalidad} className="ico-valores" />
            <div
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage({ id: "ABOUTUS.TEXTQUALITY" }),
              }}
            />
          </div>
          <div className="col-12 col-md-6 my-3">
            <img src={icocolaboracion} className="ico-valores" />
            <div
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage({ id: "ABOUTUS.TEXTCOLABORATION" }),
              }}
            />
          </div>
        </div>
      </div>
      <div className="container-fluid my-3">
        <div className="row">
          <div className="col-12 bg-aboutlast"></div>
        </div>
      </div>
    </>
  );
}

export default injectIntl(AboutUs);
