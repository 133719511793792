export const RUTAS = {
  BASE: {
    es: "/",
    en: "/en",
    ca: "/ca",
    fr: "/fr",
  },
  ABOUT: {
    es: "/sobre-nosotros",
    en: "/en/about-us",
    ca: "/ca/sobre-nosaltres",
    fr: "/fr/qui-sommes-nous",
  },
  OURCLIENTS: {
    es: "/nuestros-clientes",
    en: "/en/our-clients",
    ca: "/ca/els-nostres-clients",
    fr: "/fr/nos-clients",
  },
  PRESS: {
    es: "/prensa",
    en: "/en/press",
    ca: "/ca/premsa",
    fr: "/fr/presse",
  },
  CONTACT: {
    es: "/contacto",
    en: "/en/contact",
    ca: "/ca/contacte",
    fr: "/fr/contact",
  },
  SUSTAINABILITY: {
    es: "/sostenibilidad",
    en: "/en/sustainability",
    ca: "/ca/sostenibilitat",
    fr: "/fr/durabilite",
  },
  PLANTFORTHEPLANET: {
    es: "/plant-for-the-planet",
    en: "/en/plant-for-the-planet",
    ca: "/ca/plant-for-the-planet",
    fr: "/fr/plant-for-the-planet",
  },
  PERFUMES: {
    es: "/perfumes",
    en: "/en/perfume",
    ca: "/ca/perfums",
    fr: "/fr/parfums",
  },
  COSMETICS: {
    es: "/cosmeticos",
    en: "/en/cosmetics",
    ca: "/ca/cosmetics",
    fr: "/fr/cosmetiques",
  },
  SOLUTIONSHOME: {
    es: "/home",
    en: "/en/home",
    ca: "/ca/home",
    fr: "/fr/home",
  },
  IMASD: {
    es: "/imasd",
    en: "/en/randd",
    ca: "/ca/imesd",
    fr: "/fr/retd",
  },
  OURINNOVATIONS: {
    es: "/nuestras-innovaciones",
    en: "/en/our-innovations",
    ca: "/ca/les-nostres-innovacions",
    fr: "/fr/nos-nouveautes",
  },
  WOODLE: {
    es: "/woodle",
    en: "/en/woodle",
    ca: "/ca/woodle",
    fr: "/fr/woodle",
  },
  WOON: {
    es: "/woon",
    en: "/en/woon",
    ca: "/ca/woon",
    fr: "/fr/woon",
  },
  WOORIGIN: {
    es: "/woorigin",
    en: "/en/woorigin",
    ca: "/ca/woorigin",
    fr: "/fr/woorigin",
  },
  WOORK: {
    es: "/woork",
    en: "/en/woork",
    ca: "/ca/woork",
    fr: "/fr/woork",
  },
  PREFILL: {
    es: "/prefill",
    en: "/en/prefill",
    ca: "/ca/prefill",
    fr: "/fr/prefill",
  },
  SKINCARE: {
    es: "/skin-care",
    en: "/en/skin-care",
    ca: "/ca/skin-care",
    fr: "/fr/skin-care",
  },
  MAKEUP: {
    es: "/make-up",
    en: "/en/make-up",
    ca: "/ca/make-up",
    fr: "/fr/make-up",
  },
  RESOURCEGALLERY: {
    es: "/galeria-de-recursos",
    en: "/en/resource-gallery",
    ca: "/ca/galeria-de-recursos",
    fr: "/fr/galerie-de-ressources",
  },
  NEWS: {
    es: "/noticias",
    en: "/en/news",
    ca: "/ca/noticies",
    fr: "/fr/informations",
  },
  MEDIAAPPERANCES: {
    es: "/apariciones-en-medios",
    en: "/en/media-appearances",
    ca: "/ca/aparicions-a-mitjans",
    fr: "/fr/apparitions-dans-les-medias",
  },
  PRESSNEWS: {
    es: "/notas-de-prensa",
    en: "/en/press-releases",
    ca: "/ca/notes-de-premsa",
    fr: "/fr/communiques-de-presse",
  },
  NEWSDETAIL: {
    es: "/noticias/:id",
    en: "/en/news/:id",
    ca: "/ca/noticies/:id",
    fr: "/fr/informations/:id",
  },
  PRESSNEWSDETAIL: {
    es: "/notas-de-prensa/:id",
    en: "/en/press-releases/:id",
    ca: "/ca/notes-de-premsa/:id",
    fr: "/fr/communiques-de-presse/:id",
  },
  MEDIAAPPERANCESDETAIL: {
    es: "/apariciones-en-medios/:id",
    en: "/en/media-appearances/:id",
    ca: "/ca/aparicions-a-mitjans/:id",
    fr: "/fr/apparitions-dans-les-medias/:id",
  },
  SITEMAP: {
    es: "/mapa-sitio",
    en: "/en/site-map",
    ca: "/ca/mapa-lloc",
    fr: "/fr/plan-du-site",
  },
  LEGAL: {
    es: "/aviso-legal",
    en: "/en/legal-notice",
    ca: "/ca/avis-legal",
    fr: "/fr/avis-juridique",
  },
  INFORMATIONSYSTEM: {
    es: "/sistema-de-informacion",
    en: "/en/information-system",
    ca: "/ca/sistema-dinformacio",
    fr: "/fr/systeme-dinformation",
  },
  PRIVACIDAD: {
    es: "/politica-privacidad",
    en: "/en/privacy-policy",
    ca: "/ca/politica-privacitat",
    fr: "/fr/politique-de-confidentialite",
  },
  PRIVACIDADRRSS: {
    es: "/politica-privacidad-rrss",
    en: "/en/sn-privacy-policy",
    ca: "/ca/politica-privacitat-xxss",
    fr: "/fr/politique-de-confidentialite-rss",
  },
  COOKIES: {
    es: "/politica-cookies",
    en: "/en/cookies-policy",
    ca: "/ca/politica-cookies",
    fr: "/fr/politique-de-cookies",
  },
};

export var BLOCK = 0;
