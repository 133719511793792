import React, { useState, useEffect } from "react";
import { injectIntl } from "react-intl";
import CustomHelmet from "../components/seo/CustomHelmet";
import { useInView } from "react-intersection-observer";
import clsx from "clsx";
import { useLang } from "../translations/i18n";
import { getClients } from "../api/clients";
import { SERVER_URL } from "../utils";

function OurClients({ intl }) {
  const lang = useLang();
  const [clients, setClients] = useState([]);
  const { ref, inView /*, entry*/ } = useInView({
    /* Optional options */
    threshold: 0,
    delay: 1500,
    triggerOnce: true,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    getClients()
      .then((res) => {
        if (res.status === 200) {
          setClients(res.data);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  return (
    <>
      <CustomHelmet
        title={intl.formatMessage({ id: "OURCLIENTS.TITLESEO" }) || ""}
        description={
          intl.formatMessage({ id: "OURCLIENTS.DESCRIPTIONSEO" }) || ""
        }
      />
      <div className="container my-5">
        <div className="row">
          <div className="col-12 text-center py-5 cooperhewitt">
            <div>
              <div
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage({ id: "OURCLIENTS.SOMECLIENTS" }),
                }}
              />
            </div>
          </div>
          <div className="col-12">
            <div className="container-fluid">
              <div className="row text-center">
                {clients.map((client, index) => (
                  <div key={index} className="col-6 col-lg-3 p-3 p-lg-2 m-auto">
                    <img
                      src={`${SERVER_URL}/${client.logoURL}`}
                      className="img-fluid"
                      alt={client.name}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default injectIntl(OurClients);
