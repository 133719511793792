import { getMediaAperances } from "../api/mediaaperance";
import { useMemo } from "react";

export const useMediaAperance = () => {
  return useMemo(() => {
    const handleFetchMediaAperance = async ({
      featured,
      tag,
      setLoading = () => {},
    }) => {
      setLoading(true);
      try {
        // const { data } = await getMediaAperances(tag, featured);
        const { data } = await getMediaAperances();
        /* gestionar quan la resposta es buida (si es dona el cas) */
        if (!data) return [];

        return data || [];
      } catch (error) {
        /* gestionar el error */
        console.log("could not fetch podcasts ", error);
        return { error };
      } finally {
        setLoading(false);
      }
    };

    return {
      handleFetchMediaAperance,
    };
  }, []);
};
