import React, { useState, useEffect } from 'react'
import { injectIntl } from 'react-intl'
import CustomHelmet from '../components/seo/CustomHelmet'
import { useInView } from 'react-intersection-observer';
import { useLang } from '../translations/i18n'
import { Link } from "react-router-dom";
import { SERVER_URL } from "../utils";
import { useResourceGallerys } from "../hooks/useResourceGallery";

const GridElem = ({ title, subtitle, date, content, image, url, id, lang }) => {
	return (
	  <div className="col-12 col-lg-3 my-3">
		<div className="bgbrown px-1 py-1 br20 h-100">
		  <a href={url}
		  	target='_blank'
		  >
			<div className="mb-2 bgwhite containerimggallery br15">
			  <img src={image} className="imggallery" />
			</div>
			<h4 className="mb-0">{title}</h4>
			<p className="mb-1">{subtitle}</p>
		  </a>
		</div>
	  </div>
	);
  };
    
function ResourceGallery({ intl }) {
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState([]);
	const lang = useLang()
	const { ref, inView/*, entry*/ } = useInView({
    /* Optional options */
    threshold: 0,
		delay: 1500,
		triggerOnce: true
  });

  const { handleFetchResourceGallerys } = useResourceGallerys();

  useEffect(() => {
    window.scrollTo(0, 0);
    const asyncFetch = async () => {
      const data = await handleFetchResourceGallerys({ setLoading });
      setData(data);
    };
    asyncFetch();
  }, []);

	return (<>
		<CustomHelmet
			title={intl.formatMessage({ id: 'RESOURCEGALLERY.TITLESEO' }) || ''}
			description={intl.formatMessage({ id: 'RESOURCEGALLERY.DESCRIPTIONSEO' }) || ''}
		/>
		<div className="container my-5">
			<div className="row">
				<div className="col-12 text-center py-5">
					<div>
						<h1><div dangerouslySetInnerHTML={ { __html: intl.formatMessage({ id: 'RESOURCEGALLERY.TITLE' }) } } /></h1>
						<p><div dangerouslySetInnerHTML={ { __html: intl.formatMessage({ id: 'RESOURCEGALLERY.SUBTITLE' }) } } /></p>
					</div>
				</div>
			</div>
			<div className="row">
					{data?.map((elem) => (
						<GridElem
						title={elem.title && elem.title[lang]}
						subtitle={elem.subtitle && elem.subtitle[lang]}
						date={elem.date}
						image={`${SERVER_URL}/${elem.filesURLs[0]}`}
						url={`${SERVER_URL}/${elem.filesURLs[1]}`}
						id={elem._id}
						lang={lang}
						/>
					))}
			</div>
		</div>
	</>
  	)
}

export default injectIntl(ResourceGallery)
