import React, { useState, useEffect, useContext } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import TranslatedLink from "../components/navigation/TranslatedLink";
import { Link, Outlet, useLocation } from "react-router-dom";
import { SERVER_URL } from "../utils";
import CustomHelmet from "../components/seo/CustomHelmet";
import { useLang } from "../translations/i18n";
import { RUTAS } from "../constants/routes.constants";
import clsx from "clsx";
import videoheaderhome from "../assets/videoheaderhome.webm";
import constanteinvestigacion from "../assets/constanteinvestigacion.webm";
import SubMenuContext from "../components/context/SubMenuContext";
import DinamicCarrousel from "../components/Carrousel";

function getEveryNth(arr, nth) {
  const result = [];

  for (let i = 0; i < arr.length; i += nth) result.push(arr.slice(i, i + nth));

  return result;
}

function Lineinno() {
  return (
    <>
      <TranslatedLink to={RUTAS.WOORIGIN}>
        <div className="line ourinn1 position-relative">
          <div className="img ourinn1"></div>
          <span className="tooltiptext">
            WOORIGIN<sup>&reg;</sup>
          </span>
        </div>
      </TranslatedLink>
      <TranslatedLink to={RUTAS.WOORK}>
        <div className="line ourinn2">
          <div className="img ourinn2"></div>
          <span className="tooltiptext">
            WOORK<sup>&reg;</sup>
          </span>
        </div>
      </TranslatedLink>
      <TranslatedLink to={RUTAS.WOODLE}>
        <div className="line ourinn3">
          <div className="img ourinn3"></div>
          <span className="tooltiptext">
            WOODLE<sup>&reg;</sup>
          </span>
        </div>
      </TranslatedLink>
      <TranslatedLink to={RUTAS.WOON}>
        <div className="line ourinn4">
          <div className="img ourinn4"></div>
          <span className="tooltiptext">WOON</span>
        </div>
      </TranslatedLink>
      <TranslatedLink to={RUTAS.PREFILL}>
        <div className="line ourinn5">
          <div className="img ourinn5"></div>
          <span className="tooltiptext">
            P-REFILL<sup>&reg;</sup>
          </span>
        </div>
      </TranslatedLink>
    </>
  );
}

function Home({ intl }) {
  const { pathname } = useLocation();
  const [data, setData] = useState([]);
  const [dataPodcasts, setDataPodcasts] = useState([]);
  const [dataTags, setDataTags] = useState([]);

  const { updateMenuPosition } = useContext(SubMenuContext);

  const lang = useLang();

  useEffect(() => {
    window.scrollTo(0, 0);
    // updateMenuPosition();
  }, []);

  return (
    <>
      <CustomHelmet
        title={intl.formatMessage({ id: "HOME.TITLESEO" }) || ""}
        description={intl.formatMessage({ id: "HOME.DESCRIPTIONSEO" }) || ""}
      />
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 px-0">
            <video loop autoPlay muted playsInline className="img-fluid">
              <source src={videoheaderhome} type="video/webm" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row bg-b1">
          <div className="col-12 col-lg-6 p-5 d-flex align-items-center f20">
            <div>
              <div
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage({ id: "HOME.TEXT1" }),
                }}
              />
              <TranslatedLink
                to={RUTAS.ABOUT}
                onClick={(event) => {
                  updateMenuPosition(event.currentTarget, 1);
                }}
                className={clsx(
                  "link-style",
                  pathname === `${RUTAS.ABOUT.es}` ||
                    pathname === `${RUTAS.ABOUT.ca}` ||
                    pathname === `${RUTAS.ABOUT.en}`
                    ? " cooperhewittbold"
                    : ""
                )}
              >
                <span
                  onMouseOver={(e) => {
                    e.target.style.color = "#505050";
                  }}
                  onMouseOut={(e) => {
                    e.target.style.color = "white";
                  }}
                  className="linkspan"
                >
                  <FormattedMessage id="HOME.BTNABOUTUS" />
                </span>
              </TranslatedLink>
            </div>
          </div>
        </div>
      </div>
      <div className="container bglightgrey">
        <div className="row">
          <div className="col-12 col-lg-6 bg_soluciones"></div>
          <div className="col-12 col-lg-6 p-5 d-flex align-items-center f20">
            <div>
              <div
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage({ id: "HOME.TEXT2" }),
                }}
              />
              <TranslatedLink
                to={RUTAS.PERFUMES}
                onClick={(event) => {
                  updateMenuPosition(event.currentTarget, 0);
                }}
                className={clsx(
                  "link-style",
                  pathname === `${RUTAS.PERFUMES.es}` ||
                    pathname === `${RUTAS.PERFUMES.ca}` ||
                    pathname === `${RUTAS.PERFUMES.en}`
                    ? " cooperhewittbold"
                    : ""
                )}
              >
                <span
                  style={{ color: "white" }}
                  onMouseOver={(e) => {
                    e.target.style.color = "#505050";
                  }}
                  onMouseOut={(e) => {
                    e.target.style.color = "white";
                  }}
                >
                  <FormattedMessage id="HOME.BTNSOLUTIONS" />
                </span>
              </TranslatedLink>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid py-4 oxh">
        <div className="row">
          <div className="col-12 text-center px-0">
            <button className="my-3 btngrey">
              <TranslatedLink
                to={RUTAS.OURINNOVATIONS}
                onClick={(event) => {
                  updateMenuPosition(event.currentTarget, 0);
                }}
                className="cooperhewitt"
              >
                <FormattedMessage id="HOME.BTNOURINNOVATIONS" />
              </TranslatedLink>
            </button>
            <div className="position-relative" style={{ height: "400px" }}>
              <div className="wrapper-images">
                <div className="images-line">
                  <Lineinno />
                  <Lineinno />
                  <Lineinno />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container px-0">
        <div className="row">
          <div className="col-12 col-lg-4">
            <div className="bg_3_home h-100"></div>
          </div>
          <div className="col-12 col-lg-8">
            <div className="bg_4_home h-100"></div>
          </div>
        </div>
        <div className="row my-3">
          <div className="col-12 col-lg-6 ">
            <div className="bglightgrey h-100 d-flex align-items-center f20 p-5">
              <div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: intl.formatMessage({ id: "HOME.TEXT3" }),
                  }}
                />
                <TranslatedLink
                  to={RUTAS.OURINNOVATIONS}
                  onClick={(event) => {
                    updateMenuPosition(event.currentTarget, 0);
                  }}
                  className={clsx(
                    "link-style",
                    pathname === `${RUTAS.OURINNOVATIONS.es}` ||
                      pathname === `${RUTAS.OURINNOVATIONS.ca}` ||
                      pathname === `${RUTAS.OURINNOVATIONS.en}`
                      ? " cooperhewittbold"
                      : ""
                  )}
                >
                  <span
                    style={{ color: "white" }}
                    onMouseOver={(e) => {
                      e.target.style.color = "#505050";
                    }}
                    onMouseOut={(e) => {
                      e.target.style.color = "white";
                    }}
                  >
                    <FormattedMessage id="HOME.BTNINNOVATIONS" />
                  </span>
                </TranslatedLink>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6">
            <div className="bg_5_home h-100"></div>
          </div>
        </div>
        <div className="row my-3">
          <div className="col-12 col-lg-6 pr0 order-2 order-lg-1">
            <div>
              <video loop autoPlay muted playsInline className="img-fluid">
                <source src={constanteinvestigacion} type="video/webm" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
          <div className="col-12 col-lg-6 pl0 order-1 order-lg-2">
            <div className="bglightgrey h-100 d-flex align-items-center f20 p-5">
              <div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: intl.formatMessage({ id: "HOME.TEXT4" }),
                  }}
                />
                <TranslatedLink
                  to={RUTAS.IMASD}
                  onClick={(event) => {
                    updateMenuPosition(event.currentTarget, 0);
                  }}
                  className={clsx(
                    "link-style",
                    pathname === `${RUTAS.IMASD.es}` ||
                      pathname === `${RUTAS.IMASD.ca}` ||
                      pathname === `${RUTAS.IMASD.en}`
                      ? " cooperhewittbold"
                      : ""
                  )}
                >
                  <span
                    style={{ color: "white" }}
                    onMouseOver={(e) => {
                      e.target.style.color = "#505050";
                    }}
                    onMouseOut={(e) => {
                      e.target.style.color = "white";
                    }}
                  >
                    <FormattedMessage id="HOME.BTNRANDD" />
                  </span>
                </TranslatedLink>
              </div>
            </div>
          </div>
        </div>
        <div className="row my-3">
          <div className="col-12 col-lg-4 ">
            <div className="bglightgrey2 h-100 d-flex align-items-center f20 p-5">
              <div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: intl.formatMessage({ id: "HOME.TEXT5" }),
                  }}
                />
                <TranslatedLink
                  to={RUTAS.SUSTAINABILITY}
                  onClick={(event) => {
                    updateMenuPosition(event.currentTarget, 2);
                  }}
                  className={clsx(
                    "link-style",
                    pathname === `${RUTAS.SUSTAINABILITY.es}` ||
                      pathname === `${RUTAS.SUSTAINABILITY.ca}` ||
                      pathname === `${RUTAS.SUSTAINABILITY.en}`
                      ? " cooperhewittbold"
                      : ""
                  )}
                >
                  <span
                    style={{ color: "white" }}
                    onMouseOver={(e) => {
                      e.target.style.color = "#505050";
                    }}
                    onMouseOut={(e) => {
                      e.target.style.color = "white";
                    }}
                  >
                    <FormattedMessage id="HOME.BTNSUSTAINABLECOMMITMENT" />
                  </span>
                </TranslatedLink>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-8">
            <div className="bg_6_home h-100"></div>
          </div>
        </div>
        <DinamicCarrousel />
        <div className="row my-3">
          <div className="col-12 col-lg-4">
            <div className="bg_9_home h-100 d-flex align-items-end justify-content-center">
              <button className="my-3 btngrey">
                <TranslatedLink
                  to={RUTAS.SUSTAINABILITY}
                  onClick={(event) => {
                    updateMenuPosition(event.currentTarget, 2);
                  }}
                >
                  <FormattedMessage id="HOME.BTNSUSTAINABILITY" />
                </TranslatedLink>
              </button>
            </div>
          </div>
          <div className="col-12 col-lg-4">
            <div className="bg_11_home h-100 d-flex align-items-end justify-content-center">
              <button className="my-3 btngrey">
                <TranslatedLink
                  to={RUTAS.OURCLIENTS}
                  onClick={(event) => {
                    updateMenuPosition(event.currentTarget, 1);
                  }}
                >
                  <FormattedMessage id="HOME.BTNOURCLIENTS" />
                </TranslatedLink>
              </button>
            </div>
          </div>
          <div className="col-12 col-lg-4">
            <div className="bg_10_home h-100 d-flex align-items-end justify-content-center">
              <button className="my-3 btngrey">
                <TranslatedLink to={RUTAS.CONTACT}>
                  <FormattedMessage id="HOME.BTNCONTACT" />
                </TranslatedLink>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default injectIntl(Home);
