import React from "react";
import icoreturn from "../../assets/ico-return.svg";
import { RUTAS } from "../../constants/routes.constants";
import TranslatedLink from "./TranslatedLink";

export default function GoBack() {
  return (
    <div className="mb-4 f20">
      <TranslatedLink to={RUTAS.OURINNOVATIONS}>
        <img src={icoreturn} />{" "}
        {/* <FormattedMessage id="MENU.ABOUT" style={{ color: "white" }} /> */}
        <span>Volver a innovaciones</span>
      </TranslatedLink>
    </div>
  );
}
