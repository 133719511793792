import React, { useState, useEffect, useContext } from "react";
import TranslatedLink from "./TranslatedLink";
import clsx from "clsx";
import { FormattedMessage, injectIntl } from "react-intl";
import { RUTAS } from "../../constants/routes.constants";
import SubMenuContext from "../context/SubMenuContext";

export default function Sub({ pathname, parentRef }) {
  const { menuScrollLeft } = useContext(SubMenuContext);

  let submenuStyle = {
    left: `${menuScrollLeft}px`,
  };

  useEffect(() => {
    if (parentRef.current) {
      const rect = parentRef.current.getBoundingClientRect();
      submenuStyle = {
        left: `${rect.left}px`,
      };
    }
  }, [parentRef, menuScrollLeft]);

  return (
    <>
      {[
        RUTAS.PERFUMES.es,
        RUTAS.PERFUMES.ca,
        RUTAS.PERFUMES.en,
        RUTAS.PERFUMES.fr,
        RUTAS.COSMETICS.es,
        RUTAS.COSMETICS.ca,
        RUTAS.COSMETICS.en,
        RUTAS.COSMETICS.fr,
        RUTAS.SOLUTIONSHOME.es,
        RUTAS.SOLUTIONSHOME.ca,
        RUTAS.SOLUTIONSHOME.en,
        RUTAS.SOLUTIONSHOME.fr,
        RUTAS.OURINNOVATIONS.es,
        RUTAS.OURINNOVATIONS.ca,
        RUTAS.OURINNOVATIONS.en,
        RUTAS.OURINNOVATIONS.fr,
        RUTAS.IMASD.es,
        RUTAS.IMASD.ca,
        RUTAS.IMASD.en,
        RUTAS.IMASD.fr,
        RUTAS.PREFILL.es,
        RUTAS.PREFILL.ca,
        RUTAS.PREFILL.en,
        RUTAS.PREFILL.fr,
        RUTAS.WOORK.es,
        RUTAS.WOORK.ca,
        RUTAS.WOORK.en,
        RUTAS.WOORK.fr,
        RUTAS.WOORIGIN.es,
        RUTAS.WOORIGIN.ca,
        RUTAS.WOORIGIN.en,
        RUTAS.WOORIGIN.fr,
        RUTAS.WOON.es,
        RUTAS.WOON.ca,
        RUTAS.WOON.en,
        RUTAS.WOON.fr,
        RUTAS.WOODLE.es,
        RUTAS.WOODLE.ca,
        RUTAS.WOODLE.en,
        RUTAS.WOODLE.fr,
        RUTAS.SKINCARE.es,
        RUTAS.SKINCARE.ca,
        RUTAS.SKINCARE.en,
        RUTAS.SKINCARE.fr,
        RUTAS.MAKEUP.es,
        RUTAS.MAKEUP.ca,
        RUTAS.MAKEUP.en,
        RUTAS.MAKEUP.fr,
      ].includes(pathname) && (
        <div
          id="subsolutions"
          className="submenu px-0 bglightbrown position-relative w-100"
        >
          <ul
            className="nav d-flex align-items-start h-100 position-absolute align-items-center"
            style={submenuStyle}
          >
            <li>
              <TranslatedLink
                to={RUTAS.OURINNOVATIONS}
                className={clsx(
                  "nav-link",
                  pathname === `${RUTAS.OURINNOVATIONS.es}` ||
                    pathname === `${RUTAS.OURINNOVATIONS.ca}` ||
                    pathname === `${RUTAS.OURINNOVATIONS.en}` ||
                    pathname === `${RUTAS.OURINNOVATIONS.fr}` ||
                    pathname === `${RUTAS.PREFILL.es}` ||
                    pathname === `${RUTAS.PREFILL.ca}` ||
                    pathname === `${RUTAS.PREFILL.en}` ||
                    pathname === `${RUTAS.PREFILL.fr}` ||
                    pathname === `${RUTAS.WOORK.es}` ||
                    pathname === `${RUTAS.WOORK.ca}` ||
                    pathname === `${RUTAS.WOORK.en}` ||
                    pathname === `${RUTAS.WOORK.fr}` ||
                    pathname === `${RUTAS.WOORIGIN.es}` ||
                    pathname === `${RUTAS.WOORIGIN.ca}` ||
                    pathname === `${RUTAS.WOORIGIN.en}` ||
                    pathname === `${RUTAS.WOORIGIN.fr}` ||
                    pathname === `${RUTAS.WOON.es}` ||
                    pathname === `${RUTAS.WOON.ca}` ||
                    pathname === `${RUTAS.WOON.en}` ||
                    pathname === `${RUTAS.WOON.fr}` ||
                    pathname === `${RUTAS.WOODLE.es}` ||
                    pathname === `${RUTAS.WOODLE.ca}` ||
                    pathname === `${RUTAS.WOODLE.en}` ||
                    pathname === `${RUTAS.WOODLE.fr}`
                    ? " cooperhewittbold"
                    : ""
                )}
              >
                <FormattedMessage id="MENU.OURINNOVATIONS" />
              </TranslatedLink>
            </li>
            <li className="px-1 px-lg-3"> | </li>
            <li>
              <TranslatedLink
                to={RUTAS.IMASD}
                className={clsx(
                  "nav-link",
                  pathname === `${RUTAS.IMASD.es}` ||
                    pathname === `${RUTAS.IMASD.ca}` ||
                    pathname === `${RUTAS.IMASD.en}` ||
                    pathname === `${RUTAS.IMASD.fr}`
                    ? " cooperhewittbold"
                    : ""
                )}
              >
                <FormattedMessage id="MENU.IMASD" />
              </TranslatedLink>
            </li>
            <li className="px-1 px-lg-3"> | </li>
            <li>
              <TranslatedLink
                to={RUTAS.PERFUMES}
                className={clsx(
                  "nav-link",
                  pathname === `${RUTAS.PERFUMES.es}` ||
                    pathname === `${RUTAS.PERFUMES.ca}` ||
                    pathname === `${RUTAS.PERFUMES.en}` ||
                    pathname === `${RUTAS.PERFUMES.fr}`
                    ? " cooperhewittbold"
                    : ""
                )}
              >
                <FormattedMessage id="MENU.PERFUME" />
              </TranslatedLink>
            </li>
            <li className="px-1 px-lg-3"> | </li>
            <li>
              <TranslatedLink
                to={RUTAS.COSMETICS}
                className={clsx(
                  "nav-link",
                  pathname === `${RUTAS.COSMETICS.es}` ||
                    pathname === `${RUTAS.COSMETICS.ca}` ||
                    pathname === `${RUTAS.COSMETICS.en}` ||
                    pathname === `${RUTAS.COSMETICS.fr}` ||
                    pathname === `${RUTAS.SKINCARE.es}` ||
                    pathname === `${RUTAS.SKINCARE.ca}` ||
                    pathname === `${RUTAS.SKINCARE.en}` ||
                    pathname === `${RUTAS.SKINCARE.fr}` ||
                    pathname === `${RUTAS.MAKEUP.es}` ||
                    pathname === `${RUTAS.MAKEUP.ca}` ||
                    pathname === `${RUTAS.MAKEUP.en}` ||
                    pathname === `${RUTAS.MAKEUP.fr}`
                    ? " cooperhewittbold"
                    : ""
                )}
              >
                <FormattedMessage id="MENU.COSMETICS" />
              </TranslatedLink>
            </li>
            <li className="px-1 px-lg-3"> | </li>
            {/*
            <li>
              <TranslatedLink
                to={RUTAS.SOLUTIONSHOME}
                className={clsx(
                  "nav-link",
                  pathname === `${RUTAS.SOLUTIONSHOME.es}` ||
                    pathname === `${RUTAS.SOLUTIONSHOME.ca}` ||
                    pathname === `${RUTAS.SOLUTIONSHOME.en}` ||
                    pathname === `${RUTAS.SOLUTIONSHOME.fr}`
                    ? " cooperhewittbold"
                    : ""
                )}
              >
                <FormattedMessage id="MENU.HOME" />
              </TranslatedLink>
            </li>
            <li className="px-1 px-lg-3"> | </li>
*/}
          </ul>
        </div>
      )}
      {[
        RUTAS.ABOUT.es,
        RUTAS.ABOUT.ca,
        RUTAS.ABOUT.en,
        RUTAS.ABOUT.fr,
        RUTAS.OURCLIENTS.es,
        RUTAS.OURCLIENTS.ca,
        RUTAS.OURCLIENTS.en,
        RUTAS.OURCLIENTS.fr,
      ].includes(pathname) && (
        <div
          id="subabout"
          className="submenu px-0 bglightbrown position-relative"
        >
          <ul
            className="nav d-flex align-items-start h-100 position-absolute align-items-center"
            style={submenuStyle}
          >
            <li>
              <TranslatedLink
                to={RUTAS.ABOUT}
                className={clsx(
                  "nav-link",
                  pathname === `${RUTAS.ABOUT.es}` ||
                    pathname === `${RUTAS.ABOUT.ca}` ||
                    pathname === `${RUTAS.ABOUT.en}` ||
                    pathname === `${RUTAS.ABOUT.fr}`
                    ? " cooperhewittbold"
                    : ""
                )}
              >
                <FormattedMessage id="MENU.ABOUT" />
              </TranslatedLink>
            </li>
            <li className="px-3"> | </li>
            <li>
              <TranslatedLink
                to={RUTAS.OURCLIENTS}
                className={clsx(
                  "nav-link",
                  pathname === `${RUTAS.OURCLIENTS.es}` ||
                    pathname === `${RUTAS.OURCLIENTS.ca}` ||
                    pathname === `${RUTAS.OURCLIENTS.en}` ||
                    pathname === `${RUTAS.OURCLIENTS.fr}`
                    ? " cooperhewittbold"
                    : ""
                )}
              >
                <FormattedMessage id="MENU.OURCLIENTS" />
              </TranslatedLink>
            </li>
          </ul>
        </div>
      )}
      {[
        RUTAS.SUSTAINABILITY.es,
        RUTAS.SUSTAINABILITY.ca,
        RUTAS.SUSTAINABILITY.en,
        RUTAS.SUSTAINABILITY.fr,
        RUTAS.PLANTFORTHEPLANET.es,
        RUTAS.PLANTFORTHEPLANET.ca,
        RUTAS.PLANTFORTHEPLANET.en,
        RUTAS.PLANTFORTHEPLANET.fr,
      ].includes(pathname) && (
        <div
          id="subsustainable"
          className="submenu px-0 bglightbrown position-relative"
        >
          <ul
            className="nav d-flex align-items-start h-100 position-absolute align-items-center"
            style={submenuStyle}
          >
            <li>
              <TranslatedLink
                to={RUTAS.SUSTAINABILITY}
                className={clsx(
                  "nav-link",
                  pathname === `${RUTAS.SUSTAINABILITY.es}` ||
                    pathname === `${RUTAS.SUSTAINABILITY.ca}` ||
                    pathname === `${RUTAS.SUSTAINABILITY.en}` ||
                    pathname === `${RUTAS.SUSTAINABILITY.fr}`
                    ? " cooperhewittbold"
                    : ""
                )}
              >
                <FormattedMessage id="MENU.SUSTAINABILITY" />
              </TranslatedLink>
            </li>
            <li className="px-3"> | </li>
            <li>
              <TranslatedLink
                to={RUTAS.PLANTFORTHEPLANET}
                className={clsx(
                  "nav-link",
                  pathname === `${RUTAS.PLANTFORTHEPLANET.es}` ||
                    pathname === `${RUTAS.PLANTFORTHEPLANET.ca}` ||
                    pathname === `${RUTAS.PLANTFORTHEPLANET.en}` ||
                    pathname === `${RUTAS.PLANTFORTHEPLANET.fr}`
                    ? " cooperhewittbold"
                    : ""
                )}
              >
                <FormattedMessage id="MENU.PLANTFORTHEPLANET" />
              </TranslatedLink>
            </li>
          </ul>
        </div>
      )}
      {[
        RUTAS.PRESS.es,
        RUTAS.PRESS.ca,
        RUTAS.PRESS.en,
        RUTAS.PRESS.fr,
        RUTAS.NEWS.es,
        RUTAS.NEWS.ca,
        RUTAS.NEWS.en,
        RUTAS.NEWS.fr,
        RUTAS.MEDIAAPPERANCES.es,
        RUTAS.MEDIAAPPERANCES.ca,
        RUTAS.MEDIAAPPERANCES.en,
        RUTAS.MEDIAAPPERANCES.fr,
        RUTAS.PRESSNEWS.es,
        RUTAS.PRESSNEWS.ca,
        RUTAS.PRESSNEWS.en,
        RUTAS.PRESSNEWS.fr,
        RUTAS.MEDIAAPPERANCESDETAIL.es,
        RUTAS.MEDIAAPPERANCESDETAIL.ca,
        RUTAS.MEDIAAPPERANCESDETAIL.en,
        RUTAS.MEDIAAPPERANCESDETAIL.fr,
        RUTAS.PRESSNEWSDETAIL.es,
        RUTAS.PRESSNEWSDETAIL.ca,
        RUTAS.PRESSNEWSDETAIL.en,
        RUTAS.PRESSNEWSDETAIL.fr,
        RUTAS.PRESSNEWS.es,
        RUTAS.PRESSNEWS.ca,
        RUTAS.PRESSNEWS.en,
        RUTAS.PRESSNEWS.fr,
        RUTAS.RESOURCEGALLERY.es,
        RUTAS.RESOURCEGALLERY.ca,
        RUTAS.RESOURCEGALLERY.en,
        RUTAS.RESOURCEGALLERY.fr,
      ].some((route) => pathname.startsWith(route)) && (
        <div
          id="subpress"
          className="submenu px-0 bglightbrown position-relative"
        >
          <ul
            className="nav d-flex align-items-start h-100 position-absolute align-items-center"
            style={submenuStyle}
          >
            <li>
              <TranslatedLink
                to={RUTAS.PRESS}
                className={clsx(
                  "nav-link",
                  [
                    RUTAS.PRESS.es,
                    RUTAS.PRESS.ca,
                    RUTAS.PRESS.en,
                    RUTAS.PRESS.fr,
                    RUTAS.MEDIAAPPERANCES.es,
                    RUTAS.MEDIAAPPERANCES.ca,
                    RUTAS.MEDIAAPPERANCES.en,
                    RUTAS.MEDIAAPPERANCES.fr,
                    RUTAS.RESOURCEGALLERY.es,
                    RUTAS.RESOURCEGALLERY.ca,
                    RUTAS.RESOURCEGALLERY.en,
                    RUTAS.RESOURCEGALLERY.fr,
                  ].some((route) => pathname.startsWith(route))
                    ? " cooperhewittbold"
                    : ""
                )}
              >
                <FormattedMessage id="MENU.PRESS" />
              </TranslatedLink>
            </li>
            {/* <li className="px-3"> | </li>
            <TranslatedLink
              to={RUTAS.NEWS}
              className={clsx(
                "nav-link",
                pathname === `${RUTAS.NEWS.es}` ||
                  pathname === `${RUTAS.NEWS.ca}` ||
                  pathname === `${RUTAS.NEWS.en}` ||
                  pathname === `${RUTAS.NEWS.fr}`
                  ? " cooperhewittbold"
                  : ""
              )}
            >
              <FormattedMessage id="MENU.NEWS" />
            </TranslatedLink> */}
          </ul>
        </div>
      )}
    </>
  );
}
