import React from 'react'
import { useLang } from './i18n'
import { IntlProvider, createIntl, createIntlCache } from 'react-intl'
/* import '@formatjs/intl-relativetimeformat/polyfill'
import '@formatjs/intl-relativetimeformat/dist/locale-data/en'
import '@formatjs/intl-relativetimeformat/dist/locale-data/es'
import '@formatjs/intl-relativetimeformat/dist/locale-data/ca' */

import enMessages from './messages/en'
import esMessages from './messages/es'
import caMessages from './messages/ca'
import frMessages from './messages/fr'
import { getConfig } from './i18n'

const flattenMessages = ((nestedMessages, prefix = '') => {
	if (nestedMessages === null)
		return {}

	return Object.keys(nestedMessages).reduce((messages, key) => {
		const value       = nestedMessages[key]
		const prefixedKey = prefix ? `${prefix}.${key}` : key

		if (typeof value === 'string')
			Object.assign(messages, { [prefixedKey]: value })
		else
			Object.assign(messages, flattenMessages(value, prefixedKey))


		return messages
	}, {})
})

const allMessages = {
	en: enMessages,
	es: esMessages,
	ca: caMessages,
	fr: frMessages,
}

/* accessible intl for non-react components */
const cache = createIntlCache()
export const intl = createIntl({ locale: getConfig()?.selectedLang || 'es', messages: flattenMessages(allMessages[getConfig()?.selectedLang]) }, cache)

export function I18nProvider({ children }) {
	const locale = useLang()
	const messages = flattenMessages(allMessages[locale])

	return (
		<IntlProvider locale={locale} messages={messages}>
			{children}
		</IntlProvider>
	)
}
