import React, { useMemo, useState, useEffect } from "react";
import { injectIntl } from "react-intl";
import CustomHelmet from "../components/seo/CustomHelmet";
import { useInView } from "react-intersection-observer";
import clsx from "clsx";
import { useLang } from "../translations/i18n";
import showscontacto from "../assets/shows-contacto.png";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import { postContact } from "../api/contact";
import { alertError, alertSuccess } from "../utils/logger";
import { useNavigate } from "react-router-dom";

function getEmptyContact() {
  return {
    name: "",
    email: "",
    department: "",
    message: "",
  };
}

function Contact({ intl }) {
  const [contact, setContact] = useState(getEmptyContact());

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const formDataObj = Object.fromEntries(formData.entries());
    postContact(formDataObj)
      .then((res) => {
        if (res.status === 201) {
          alertSuccess({
            title: "Saved!",
            customMessage: intl.formatMessage({ id: "CONTACT.CONTACTCREATED" }),
          });
        }
      })
      .catch((error) => {
        alertError({
          error: error,
          customMessage: intl.formatMessage({ id: "CONTACT.COULDNOTSAVE" }),
        });
      });
  };

  const lang = useLang();
  const { ref, inView /*, entry*/ } = useInView({
    /* Optional options */
    threshold: 0,
    delay: 1500,
    triggerOnce: true,
  });

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "",
  });
  const center = useMemo(() => ({ lat: 42.068995, lng: 2.290899 }), []);
  const navigate = useNavigate();

  const handleChange = (element, lang) => (event) => {
    if (lang) {
      if (event.target.value === " ") return;
      if (!contact[element]) contact[element] = {};
      let newText = contact[element];
      newText[lang] = event.target.value;
      setContact({ ...contact, [element]: newText });
    } else setContact({ ...contact, [element]: event.target.value });
  };

  return (
    <>
      <CustomHelmet
        title={intl.formatMessage({ id: "CONTACT.TITLESEO" }) || ""}
        description={intl.formatMessage({ id: "CONTACT.DESCRIPTIONSEO" }) || ""}
      />
      <div className="container-fluid bg-headercontacto">
        <div className="row">
          <div className="col-12">
            <div className="container">
              <div className="row">
                <div className="col-12 col-lg-4 py-5">
                  <h1 className="my-5 cooperhewittbold">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: intl.formatMessage({ id: "CONTACT.CONTACT" }),
                      }}
                    />
                  </h1>
                  <meta
                    HTTP-EQUIV="Content-type"
                    CONTENT="text/html; charset=UTF-8"
                  ></meta>
                  <form
                    action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8&orgId=00D09000007kJPd"
                    method="POST"
                    onSubmit={handleSubmit}
                  >
                    <input
                      type="hidden"
                      name="oid"
                      value="00D09000007kJPd"
                      className="w-100 my-2 cooperhewitt"
                    />
                    <input
                      type="hidden"
                      name="retURL"
                      value="http://www.pujolasos.com"
                    />
                    <input
                      type="hidden"
                      name="debug"
                      value={1}
                      className="w-100 my-2 cooperhewitt"
                    />
                    <input
                      type="hidden"
                      name="debugEmail"
                      value="angel.jr@pujolasos.com"
                      className="w-100 my-2 cooperhewitt"
                    />
                    <label for="first_name" className="cooperhewitt">
                      First Name
                    </label>
                    <input
                      id="first_name"
                      maxlength="40"
                      name="first_name"
                      size="20"
                      type="text"
                      className="w-100 my-2 cooperhewitt"
                    />
                    <br />
                    <label for="last_name" className="cooperhewitt">
                      Last Name
                    </label>
                    <input
                      id="last_name"
                      maxlength="80"
                      name="last_name"
                      size="20"
                      type="text"
                      className="w-100 my-2 cooperhewitt"
                    />
                    <br />
                    <label for="email" className="cooperhewitt">
                      Email
                    </label>
                    <input
                      id="email"
                      maxlength="80"
                      name="email"
                      size="20"
                      type="text"
                      className="w-100 my-2 cooperhewitt"
                    />
                    <br />
                    <label for="company" className="cooperhewitt">
                      Company
                    </label>
                    <input
                      id="company"
                      maxlength="40"
                      name="company"
                      size="20"
                      type="text"
                      className="w-100 my-2 cooperhewitt"
                    />
                    <br />
                    <label for="phone" className="cooperhewitt">
                      Phone
                    </label>
                    <input
                      id="phone"
                      maxlength="40"
                      name="phone"
                      size="20"
                      type="text"
                      className="w-100 my-2 cooperhewitt"
                    />
                    <br />
                    <label className="cooperhewitt">Sector</label>
                    <select
                      id="00N0900000EwYfl"
                      name="00N0900000EwYfl"
                      title="Sector"
                      className="w-100 my-2 cooperhewitt"
                      style={{ backgroundColor: "transparent" }}
                    >
                      <option value="">--None--</option>
                      <option value="Perfumería">Perfumería</option>
                      <option value="Cosmética">Cosmética</option>
                      <option value="Ambientación">Ambientación</option>
                      <option value="Food">Food</option>
                      <option value="Bebidas">Bebidas</option>
                      <option value="Otros">Otros</option>
                    </select>
                    <br />
                    <label for="00N0900000ExhZp" className="cooperhewitt">
                      Descripción
                    </label>
                    <textarea
                      id="00N0900000ExhZp"
                      name="00N0900000ExhZp"
                      rows="3"
                      type="text"
                      wrap="soft"
                      className="w-100 my-2 cooperhewitt"
                    ></textarea>
                    <input
                      type="submit"
                      name="submit"
                      className="my-5 cooperhewitt buttonsubmit"
                    />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="row">
          <div className="col-12 bglightgrey3 text-center py-5">
            <h1 className="colorgrey cooperhewitt">
              <div
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage({ id: "CONTACT.SHOWS" }),
                }}
              />
            </h1>
            <img src={showscontacto} className="img-fluid" />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="container">
              <div className="row">
                <div className="col-12 col-lg-4 py-5 cooperhewitt">
                  <div>HEADQUARDTERS</div>
                  <p>
                    Pol. Ind. Av. Fontsanta s/n
                    <br />
                    08572 Sant Pere de Torelló
                    <br />
                    Barcelona - SPAIN
                  </p>
                  <div>Tel.:(+34) 93 858 44 51</div>
                  <div>Fax:(+34) 93 850 90 58</div>
                  <div>comercial@pujolasos.com</div>
                </div>
                <div className="col-12 col-lg-4 py-5 cooperhewitt">
                  <div>AIRPORTS DISTANCE</div>
                  <p>
                    Barcelona: 100km, 1h 30min
                    <br />
                    Girona: 60km, 1h 10min
                  </p>
                </div>
                <div className="col-12 col-lg-4 py-5 cooperhewitt">
                  <div>US & CANADA</div>
                  <p>
                    SGB Packaging Group, Inc.
                    <br />
                    401 Hackensack Avenue, 7th Floor
                    <br />
                    Hackensack, NJ 07601 USA
                  </p>
                  <div>Tel.:(201) 488.3030</div>
                  <div>Fax:(201) 488.3666</div>
                  <div>info@sgbpackaging.com</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 text-center px-0 cooperhewitt">
            {!isLoaded ? (
              <h1>Loading...</h1>
            ) : (
              <GoogleMap
                mapContainerClassName="map-container"
                center={center}
                zoom={16}
              >
                <Marker position={{ lat: 42.068995, lng: 2.2908 }} />
              </GoogleMap>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default injectIntl(Contact);
