import React, { useState, useRef, useEffect } from "react";
import SubMenuContext from "./SubMenuContext";

const SubMenuProvider = ({ children }) => {
  const [menuScrollLeft, setMenuScrollLeft] = useState(0);
  const [buttonPositions, setButtonPositions] = useState([]);
  const buttonRefs = useRef([
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ]).current;

  const updateMenuPosition = (refElement = null, menuposition = -1) => {
    if (refElement) {
      let menuPosition;
      if (menuposition === -1)
        menuPosition = refElement?.getBoundingClientRect().left;
      else menuPosition = buttonPositions[menuposition];
      // const menuPosition = buttonPositions[menuposition];
      setMenuScrollLeft(menuPosition);
    }
  };

  const updateButtonPositions = () => {
    const positions = buttonRefs?.map(
      (ref) => ref?.current?.getBoundingClientRect().left + 18
    );
    setButtonPositions(positions);
  };

  useEffect(() => {
    updateButtonPositions();
    window.addEventListener("resize", updateButtonPositions);
    return () => {
      window.removeEventListener("resize", updateButtonPositions);
    };
  }, []);

  return (
    <SubMenuContext.Provider
      value={{
        menuScrollLeft,
        updateMenuPosition,
        buttonPositions,
        updateButtonPositions,
        buttonRefs,
      }}
    >
      {children}
    </SubMenuContext.Provider>
  );
};

export default SubMenuProvider;
