import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import App from "./App";
import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";
import OurClients from "./pages/OurClients";
import Sustainability from "./pages/Sustainability";
import PlantForThePlanet from "./pages/PlantForThePlanet";
import Perfumes from "./pages/Perfumes";
import Cosmetics from "./pages/Cosmetics";
import SolutionsHome from "./pages/SolutionsHome";
import ImasD from "./pages/ImasD";
import OurInnovations from "./pages/OurInnovations";
import Woodle from "./pages/Woodle";
import Woon from "./pages/Woon";
import Woorigin from "./pages/Woorigin";
import Woork from "./pages/Woork";
import PRefill from "./pages/PRefill";
import SkinCare from "./pages/SkinCare";
import MakeUp from "./pages/MakeUp";
import Contact from "./pages/Contact";
import Press from "./pages/Press";
import ResourceGallery from "./pages/ResourceGallery";
import News from "./pages/News";
import MediaApperances from "./pages/MediaApperances";
import PressNews from "./pages/PressNews";
import Sitemap from "./pages/Sitemap";
import AvisoLegal from "./pages/AvisoLegal";
import PoliticaPrivacidad from "./pages/PoliticaPrivacidad";
import PoliticaPrivacidadRRSS from "./pages/PoliticaPrivacidadRRSS";
import PoliticaCookies from "./pages/PoliticaCookies";
import NewsDetail from "./pages/NewsDetail";
import PressnewsDetail from "./pages/PressnewsDetail";
import MediaApperancesDetail from "./pages/MediaApperancesDetail";
import InformationSystem from "./pages/InformationSystem";
import { RUTAS } from "./constants/routes.constants";
import NotFound from "./pages/Page404";

function Rutas() {
  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <Routes>
        <Route path="/" element={<App />}>
          <Route path={RUTAS.BASE.es} element={<Home />} />
          <Route path={RUTAS.BASE.ca} element={<Home />} />
          <Route path={RUTAS.BASE.en} element={<Home />} />
          <Route path={RUTAS.BASE.fr} element={<Home />} />

          <Route path={RUTAS.ABOUT.es} element={<AboutUs />} />
          <Route path={RUTAS.ABOUT.ca} element={<AboutUs />} />
          <Route path={RUTAS.ABOUT.en} element={<AboutUs />} />
          <Route path={RUTAS.ABOUT.fr} element={<AboutUs />} />

          <Route path={RUTAS.OURCLIENTS.es} element={<OurClients />} />
          <Route path={RUTAS.OURCLIENTS.ca} element={<OurClients />} />
          <Route path={RUTAS.OURCLIENTS.en} element={<OurClients />} />
          <Route path={RUTAS.OURCLIENTS.fr} element={<OurClients />} />

          <Route path={RUTAS.PRESS.es} element={<Press />} />
          <Route path={RUTAS.PRESS.ca} element={<Press />} />
          <Route path={RUTAS.PRESS.en} element={<Press />} />
          <Route path={RUTAS.PRESS.fr} element={<Press />} />

          <Route path={RUTAS.CONTACT.es} element={<Contact />} />
          <Route path={RUTAS.CONTACT.ca} element={<Contact />} />
          <Route path={RUTAS.CONTACT.en} element={<Contact />} />
          <Route path={RUTAS.CONTACT.fr} element={<Contact />} />

          <Route path={RUTAS.SUSTAINABILITY.es} element={<Sustainability />} />
          <Route path={RUTAS.SUSTAINABILITY.ca} element={<Sustainability />} />
          <Route path={RUTAS.SUSTAINABILITY.en} element={<Sustainability />} />
          <Route path={RUTAS.SUSTAINABILITY.fr} element={<Sustainability />} />

          <Route
            path={RUTAS.PLANTFORTHEPLANET.es}
            element={<PlantForThePlanet />}
          />
          <Route
            path={RUTAS.PLANTFORTHEPLANET.ca}
            element={<PlantForThePlanet />}
          />
          <Route
            path={RUTAS.PLANTFORTHEPLANET.en}
            element={<PlantForThePlanet />}
          />
          <Route
            path={RUTAS.PLANTFORTHEPLANET.fr}
            element={<PlantForThePlanet />}
          />

          <Route path={RUTAS.PERFUMES.es} element={<Perfumes />} />
          <Route path={RUTAS.PERFUMES.ca} element={<Perfumes />} />
          <Route path={RUTAS.PERFUMES.en} element={<Perfumes />} />
          <Route path={RUTAS.PERFUMES.fr} element={<Perfumes />} />

          <Route path={RUTAS.COSMETICS.es} element={<Cosmetics />} />
          <Route path={RUTAS.COSMETICS.ca} element={<Cosmetics />} />
          <Route path={RUTAS.COSMETICS.en} element={<Cosmetics />} />
          <Route path={RUTAS.COSMETICS.fr} element={<Cosmetics />} />

          <Route path={RUTAS.SOLUTIONSHOME.es} element={<SolutionsHome />} />
          <Route path={RUTAS.SOLUTIONSHOME.ca} element={<SolutionsHome />} />
          <Route path={RUTAS.SOLUTIONSHOME.en} element={<SolutionsHome />} />
          <Route path={RUTAS.SOLUTIONSHOME.fr} element={<SolutionsHome />} />

          <Route path={RUTAS.IMASD.es} element={<ImasD />} />
          <Route path={RUTAS.IMASD.ca} element={<ImasD />} />
          <Route path={RUTAS.IMASD.en} element={<ImasD />} />
          <Route path={RUTAS.IMASD.fr} element={<ImasD />} />

          <Route path={RUTAS.OURINNOVATIONS.es} element={<OurInnovations />} />
          <Route path={RUTAS.OURINNOVATIONS.ca} element={<OurInnovations />} />
          <Route path={RUTAS.OURINNOVATIONS.en} element={<OurInnovations />} />
          <Route path={RUTAS.OURINNOVATIONS.fr} element={<OurInnovations />} />

          <Route path={RUTAS.WOODLE.es} element={<Woodle />} />
          <Route path={RUTAS.WOODLE.ca} element={<Woodle />} />
          <Route path={RUTAS.WOODLE.en} element={<Woodle />} />
          <Route path={RUTAS.WOODLE.fr} element={<Woodle />} />

          <Route path={RUTAS.WOON.es} element={<Woon />} />
          <Route path={RUTAS.WOON.ca} element={<Woon />} />
          <Route path={RUTAS.WOON.en} element={<Woon />} />
          <Route path={RUTAS.WOON.fr} element={<Woon />} />

          <Route path={RUTAS.WOORIGIN.es} element={<Woorigin />} />
          <Route path={RUTAS.WOORIGIN.ca} element={<Woorigin />} />
          <Route path={RUTAS.WOORIGIN.en} element={<Woorigin />} />
          <Route path={RUTAS.WOORIGIN.fr} element={<Woorigin />} />

          <Route path={RUTAS.WOORK.es} element={<Woork />} />
          <Route path={RUTAS.WOORK.ca} element={<Woork />} />
          <Route path={RUTAS.WOORK.en} element={<Woork />} />
          <Route path={RUTAS.WOORK.fr} element={<Woork />} />

          <Route path={RUTAS.PREFILL.es} element={<PRefill />} />
          <Route path={RUTAS.PREFILL.ca} element={<PRefill />} />
          <Route path={RUTAS.PREFILL.en} element={<PRefill />} />
          <Route path={RUTAS.PREFILL.fr} element={<PRefill />} />

          <Route path={RUTAS.SKINCARE.es} element={<SkinCare />} />
          <Route path={RUTAS.SKINCARE.ca} element={<SkinCare />} />
          <Route path={RUTAS.SKINCARE.en} element={<SkinCare />} />
          <Route path={RUTAS.SKINCARE.fr} element={<SkinCare />} />

          <Route path={RUTAS.MAKEUP.es} element={<MakeUp />} />
          <Route path={RUTAS.MAKEUP.ca} element={<MakeUp />} />
          <Route path={RUTAS.MAKEUP.en} element={<MakeUp />} />
          <Route path={RUTAS.MAKEUP.fr} element={<MakeUp />} />

          <Route
            path={RUTAS.RESOURCEGALLERY.es}
            element={<ResourceGallery />}
          />
          <Route
            path={RUTAS.RESOURCEGALLERY.ca}
            element={<ResourceGallery />}
          />
          <Route
            path={RUTAS.RESOURCEGALLERY.en}
            element={<ResourceGallery />}
          />
          <Route
            path={RUTAS.RESOURCEGALLERY.fr}
            element={<ResourceGallery />}
          />

          <Route path={RUTAS.NEWS.es} element={<News />} />
          <Route path={RUTAS.NEWS.ca} element={<News />} />
          <Route path={RUTAS.NEWS.en} element={<News />} />
          <Route path={RUTAS.NEWS.fr} element={<News />} />

          <Route
            path={RUTAS.MEDIAAPPERANCES.es}
            element={<MediaApperances />}
          />
          <Route
            path={RUTAS.MEDIAAPPERANCES.ca}
            element={<MediaApperances />}
          />
          <Route
            path={RUTAS.MEDIAAPPERANCES.en}
            element={<MediaApperances />}
          />
          <Route
            path={RUTAS.MEDIAAPPERANCES.fr}
            element={<MediaApperances />}
          />

          <Route path={RUTAS.PRESSNEWS.es} element={<PressNews />} />
          <Route path={RUTAS.PRESSNEWS.ca} element={<PressNews />} />
          <Route path={RUTAS.PRESSNEWS.en} element={<PressNews />} />
          <Route path={RUTAS.PRESSNEWS.fr} element={<PressNews />} />

          <Route path={RUTAS.NEWSDETAIL.es} element={<NewsDetail />} />
          <Route path={RUTAS.NEWSDETAIL.ca} element={<NewsDetail />} />
          <Route path={RUTAS.NEWSDETAIL.en} element={<NewsDetail />} />
          <Route path={RUTAS.NEWSDETAIL.fr} element={<NewsDetail />} />

          <Route
            path={RUTAS.PRESSNEWSDETAIL.es}
            element={<PressnewsDetail />}
          />
          <Route
            path={RUTAS.PRESSNEWSDETAIL.ca}
            element={<PressnewsDetail />}
          />
          <Route
            path={RUTAS.PRESSNEWSDETAIL.en}
            element={<PressnewsDetail />}
          />
          <Route
            path={RUTAS.PRESSNEWSDETAIL.fr}
            element={<PressnewsDetail />}
          />

          <Route
            path={RUTAS.MEDIAAPPERANCESDETAIL.es}
            element={<MediaApperancesDetail />}
          />
          <Route
            path={RUTAS.MEDIAAPPERANCESDETAIL.ca}
            element={<MediaApperancesDetail />}
          />
          <Route
            path={RUTAS.MEDIAAPPERANCESDETAIL.en}
            element={<MediaApperancesDetail />}
          />
          <Route
            path={RUTAS.MEDIAAPPERANCESDETAIL.fr}
            element={<MediaApperancesDetail />}
          />

          <Route path={RUTAS.SITEMAP.es} element={<Sitemap />} />
          <Route path={RUTAS.SITEMAP.ca} element={<Sitemap />} />
          <Route path={RUTAS.SITEMAP.en} element={<Sitemap />} />
          <Route path={RUTAS.SITEMAP.fr} element={<Sitemap />} />

          <Route path={RUTAS.LEGAL.es} element={<AvisoLegal />} />
          <Route path={RUTAS.LEGAL.ca} element={<AvisoLegal />} />
          <Route path={RUTAS.LEGAL.en} element={<AvisoLegal />} />
          <Route path={RUTAS.LEGAL.fr} element={<AvisoLegal />} />

          <Route path={RUTAS.PRIVACIDAD.es} element={<PoliticaPrivacidad />} />
          <Route path={RUTAS.PRIVACIDAD.ca} element={<PoliticaPrivacidad />} />
          <Route path={RUTAS.PRIVACIDAD.en} element={<PoliticaPrivacidad />} />
          <Route path={RUTAS.PRIVACIDAD.fr} element={<PoliticaPrivacidad />} />

          <Route
            path={RUTAS.INFORMATIONSYSTEM.es}
            element={<InformationSystem />}
          />
          <Route
            path={RUTAS.INFORMATIONSYSTEM.ca}
            element={<InformationSystem />}
          />
          <Route
            path={RUTAS.INFORMATIONSYSTEM.en}
            element={<InformationSystem />}
          />
          <Route
            path={RUTAS.INFORMATIONSYSTEM.fr}
            element={<InformationSystem />}
          />

          <Route
            path={RUTAS.PRIVACIDADRRSS.es}
            element={<PoliticaPrivacidadRRSS />}
          />
          <Route
            path={RUTAS.PRIVACIDADRRSS.ca}
            element={<PoliticaPrivacidadRRSS />}
          />
          <Route
            path={RUTAS.PRIVACIDADRRSS.en}
            element={<PoliticaPrivacidadRRSS />}
          />
          <Route
            path={RUTAS.PRIVACIDADRRSS.fr}
            element={<PoliticaPrivacidadRRSS />}
          />

          <Route path={RUTAS.COOKIES.es} element={<PoliticaCookies />} />
          <Route path={RUTAS.COOKIES.ca} element={<PoliticaCookies />} />
          <Route path={RUTAS.COOKIES.en} element={<PoliticaCookies />} />
          <Route path={RUTAS.COOKIES.fr} element={<PoliticaCookies />} />

          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default Rutas;
