import axios from 'axios'
import { API } from '../../utils/index'
const instance = axios.create()

export const getTexts = async () => {
	const _endpoint = 'text'
	const URL = `${API}/${_endpoint}`

	return instance.get(URL)
}

export const getText = async (id) => {
	const _endpoint = `text/${id}`
	const URL = `${API}/${_endpoint}`

	return instance.get(URL)
}