import { getNew, getNews } from "../api/news";
import { useMemo } from "react";

export const useNews = () => {
  return useMemo(() => {
    const handleFetchNews = async ({
      featured,
      tag,
      setLoading = () => {},
    }) => {
      setLoading(true);

      try {
        const { data } = await getNews(tag, featured);

        /* gestionar quan la resposta es buida (si es dona el cas) */
        if (!data) return [];

        return data || [];
      } catch (error) {
        /* gestionar el error */
        console.log("could not fetch podcasts ", error);
        return { error };
      } finally {
        setLoading(false);
      }
    };

    const handleFetchNew = async ({ id, setLoading = () => {} }) => {
      setLoading(true);

      try {
        const { data } = await getNew(id);
        /* gestionar quan la resposta es buida (si es dona el cas) */
        if (!data) return {};
        return { data };
      } catch (error) {
        /* TODO -> Handle error */
        /* gestionar el error */
        console.log("could not fetch podcast with id: ", id, error);
        return { error, status: 404 };
      } finally {
        setLoading(false);
      }
    };

    return {
      handleFetchNews,
      handleFetchNew,
    };
  }, []);
};
