import React, { useState, useEffect } from "react";
import { injectIntl } from "react-intl";
import CustomHelmet from "../components/seo/CustomHelmet";
import { useInView } from "react-intersection-observer";
import clsx from "clsx";
import { useLang } from "../translations/i18n";
import logosustainability from "../assets/logo-sustainability.svg";
import icopftp1 from "../assets/ico-pftp1.svg";
import icopftp2 from "../assets/ico-pftp2.svg";
import icopftp3 from "../assets/ico-pftp3.svg";
import icopftp4 from "../assets/ico-pftp4.svg";
import icopftp5 from "../assets/ico-pftp5.svg";
import icopftp6 from "../assets/ico-pftp6.svg";
import icopftp7 from "../assets/ico-pftp7.svg";
import icopftp8 from "../assets/ico-pftp8.svg";

function AboutUs({ intl }) {
  const lang = useLang();
  const { ref, inView /*, entry*/ } = useInView({
    /* Optional options */
    threshold: 0,
    delay: 1500,
    triggerOnce: true,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <CustomHelmet
        title={intl.formatMessage({ id: "PLANTFORTHEPLANET.TITLESEO" }) || ""}
        description={
          intl.formatMessage({ id: "PLANTFORTHEPLANET.DESCRIPTIONSEO" }) || ""
        }
      />
      <div className="container-fluid bg-plantfortheplanet">
        <div className="row">
          <div className="col-12 py-5">
            <img src={logosustainability} className="m-4" />
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-12 py-5 title">
            <h1>
              <div
                className="cooperhewitt"
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage({
                    id: "PLANTFORTHEPLANET.PLANTFORTHEPLANET",
                  }),
                }}
              />
            </h1>
          </div>
          <div className="col-12 col-lg-6">
            <div
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage({ id: "PLANTFORTHEPLANET.TEXT1" }),
              }}
            />
          </div>
          <div className="col-12 col-lg-6">
            <div
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage({ id: "PLANTFORTHEPLANET.TEXT2" }),
              }}
            />
          </div>
          <div className="col-12 py-5 text-center bglightgrey">
            <div
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage({ id: "PLANTFORTHEPLANET.TEXT3" }),
              }}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-lg-6 bglightgrey2 p-5 d-flex align-items-center">
            <div>
              <div
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage({ id: "PLANTFORTHEPLANET.TEXT4" }),
                }}
              />
              <button className="link-style">
                <a
                  href="https://forest.plant-for-the-planet.org/pujolasos/"
                  target="_blank"
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html: intl.formatMessage({
                        id: "PLANTFORTHEPLANET.KNOWMORE",
                      }),
                    }}
                  />
                </a>
              </button>
            </div>
          </div>
          <div className="col-12 col-lg-6 bg-billonarboles"></div>
        </div>
        <div className="row">
          <div className="col-12 col-lg-8 offset-lg-2 text-center my-5">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12 my-4 cooperhewitt">
                  <h3>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: intl.formatMessage({
                          id: "PLANTFORTHEPLANET.TEXTANDALSO",
                        }),
                      }}
                    />
                  </h3>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-lg-4">
                  <div>
                    <img src={icopftp1} className="img-fluid" />
                  </div>
                  <div className="my-3 cooperhewitt">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: intl.formatMessage({
                          id: "PLANTFORTHEPLANET.P1",
                        }),
                      }}
                    />
                  </div>
                </div>
                <div className="col-12 col-lg-4">
                  <div>
                    <img src={icopftp2} className="img-fluid" />
                  </div>
                  <div className="my-3 cooperhewitt">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: intl.formatMessage({
                          id: "PLANTFORTHEPLANET.P2",
                        }),
                      }}
                    />
                  </div>
                </div>
                <div className="col-12 col-lg-4">
                  <div>
                    <img src={icopftp3} className="img-fluid" />
                  </div>
                  <div className="my-3 cooperhewitt">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: intl.formatMessage({
                          id: "PLANTFORTHEPLANET.P3",
                        }),
                      }}
                    />
                  </div>
                </div>
                <div className="col-12 col-lg-4">
                  <div>
                    <img src={icopftp4} className="img-fluid" />
                  </div>
                  <div className="my-3 cooperhewitt">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: intl.formatMessage({
                          id: "PLANTFORTHEPLANET.P4",
                        }),
                      }}
                    />
                  </div>
                </div>
                <div className="col-12 col-lg-4">
                  <div>
                    <img src={icopftp5} className="img-fluid" />
                  </div>
                  <div className="my-3 cooperhewitt">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: intl.formatMessage({
                          id: "PLANTFORTHEPLANET.P5",
                        }),
                      }}
                    />
                  </div>
                </div>
                <div className="col-12 col-lg-4">
                  <div>
                    <img src={icopftp6} className="img-fluid" />
                  </div>
                  <div className="my-3 cooperhewitt">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: intl.formatMessage({
                          id: "PLANTFORTHEPLANET.P6",
                        }),
                      }}
                    />
                  </div>
                </div>
                <div className="col-12 col-lg-4 offset-lg-2">
                  <div>
                    <img src={icopftp7} className="img-fluid" />
                  </div>
                  <div className="my-3 cooperhewitt">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: intl.formatMessage({
                          id: "PLANTFORTHEPLANET.P7",
                        }),
                      }}
                    />
                  </div>
                </div>
                <div className="col-12 col-lg-4">
                  <div>
                    <img src={icopftp8} className="img-fluid" />
                  </div>
                  <div className="my-3 cooperhewitt">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: intl.formatMessage({
                          id: "PLANTFORTHEPLANET.P8",
                        }),
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default injectIntl(AboutUs);
