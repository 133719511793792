import React, { useEffect } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import CustomHelmet from '../components/seo/CustomHelmet'
import TranslatedLink from '../components/navigation/TranslatedLink'
import { RUTAS } from '../constants/routes.constants'

function Sitemap({ intl }) {


	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	return (<>
		<CustomHelmet
			title={intl.formatMessage({ id: 'SITEMAP.TITLESEO' }) || ''}
			description={intl.formatMessage({ id: 'SITEMAP.DESCRIPTIONSEO' }) || ''}
		/>
	<div className="container-fluid px-3 px-lg-5 pb-5">
			<div className="row">
				<div className="col-12">
					<h1><FormattedMessage id='FOOTER.SITEMAPLOW' /></h1>
					<ul className="my-5">
						<li style={{'list-style':'none'}}><TranslatedLink to={RUTAS.BASE} className="footerlinks" style={{ textTransform: 'uppercase'}}><FormattedMessage id='MENU.INICIO' /></TranslatedLink></li>
						<li style={{'list-style':'none'}}><TranslatedLink to={RUTAS.VIDEOS} className="footerlinks" style={{ textTransform: 'uppercase'}}><FormattedMessage id='MENU.VIDEOS' /></TranslatedLink></li>
						<li style={{'list-style':'none'}}><TranslatedLink to={RUTAS.PODCASTS} className="footerlinks" style={{ textTransform: 'uppercase'}}><FormattedMessage id='MENU.PODCASTS' /></TranslatedLink></li>
						<li style={{'list-style':'none'}}><TranslatedLink to={RUTAS.ABOUT} className="footerlinks" style={{ textTransform: 'uppercase'}}><FormattedMessage id='MENU.THEINSPIRINGHUB' /></TranslatedLink></li>
						<li style={{'list-style':'none'}}><TranslatedLink to={RUTAS.LEGAL} className="footerlinks"><FormattedMessage id='FOOTER.AVISOLEGAL' /></TranslatedLink></li>
						<li style={{'list-style':'none'}}><TranslatedLink to={RUTAS.SITEMAP} className="footerlinks"><FormattedMessage id='FOOTER.SITEMAP' /></TranslatedLink></li>
						<li style={{'list-style':'none'}}><TranslatedLink to={RUTAS.PRIVACIDAD} className="footerlinks"><FormattedMessage id='FOOTER.POLITICADEPRIVACIDAD' /></TranslatedLink></li>
						<li style={{'list-style':'none'}}><TranslatedLink to={RUTAS.COOKIES} className="footerlinks"><FormattedMessage id='FOOTER.COOKIES' /></TranslatedLink></li>
					</ul>
				</div>
			</div>
		</div>
	</>
    	)
}

export default injectIntl(Sitemap)
